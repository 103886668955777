var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"registration"},[_c('div',{staticClass:"registration-header"},[_c('p',[_vm._v("《"+_vm._s(_vm.title)+"》")]),_c('h1',[_vm._v("债权人预登记")])]),_c('van-form',{ref:"form",staticClass:"form_info",on:{"submit":_vm.onSubmit}},[_c('h2',{staticClass:"form_info-title"},[_vm._v("请根据真实情况填写以下信息")]),_c('van-field',{attrs:{"type":"text","name":"name","label":"Name of the creditor：","placeholder":"Please enter name of the creditor","rules":[
                { required: true, message: 'Please enter name of the creditor' }
            ]},model:{value:(_vm.fields.name),callback:function ($$v) {_vm.$set(_vm.fields, "name", $$v)},expression:"fields.name"}}),_c('van-field',{attrs:{"type":"text","name":"mobile","label":"Mobile number：","placeholder":"Please enter your mobile number","maxlength":"11","rules":[
                { required: true, message: 'Please enter your mobile number' },
                { pattern: /^\d{11}$/, message: '请输入正确的手机号码' }
            ]},model:{value:(_vm.fields.mobile),callback:function ($$v) {_vm.$set(_vm.fields, "mobile", $$v)},expression:"fields.mobile"}}),_c('van-field',{attrs:{"type":"text","name":"sms_code","label":"验证码：","placeholder":"请输入验证码","rules":[
                { required: true, message: '请输入验证码' }
            ]},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('send-verify-code',{staticClass:"form_info-text_get_vcode",attrs:{"mobile":_vm.fields.mobile,"params":{ case_code: _vm.$route.query.v },"event":"creditor_register"},on:{"fail":function($event){return _vm.$toast.fail($event)},"success":function($event){return _vm.$toast.success('Sent successfully')}}},[_vm._v(" 获取验证码 ")])]},proxy:true}]),model:{value:(_vm.fields.sms_code),callback:function ($$v) {_vm.$set(_vm.fields, "sms_code", $$v)},expression:"fields.sms_code"}}),_c('van-field',{attrs:{"type":"text","name":"address","label":"Contact address：","placeholder":"请输入联系地址","rules":[
                { required: true, message: '请输入联系地址' }
            ]},model:{value:(_vm.fields.address),callback:function ($$v) {_vm.$set(_vm.fields, "address", $$v)},expression:"fields.address"}}),_c('van-field',{attrs:{"type":"text","name":"wechat","label":"Wechat account：","placeholder":"请输入你的微信号码"},model:{value:(_vm.fields.wechat),callback:function ($$v) {_vm.$set(_vm.fields, "wechat", $$v)},expression:"fields.wechat"}}),_c('van-button',{staticClass:"btn-submit",attrs:{"native-type":"submit"}},[_vm._v("立即提交")])],1),_vm._m(0),_vm._m(1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('figure',{staticClass:"registration-tencent_isux"},[_c('img',{attrs:{"src":require("../assets/images/qr_code-tencent_isux.png")}}),_c('figcaption',[_vm._v("Follow the public number to learn more about the case")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"registration-footer"},[_c('h2',[_vm._v("破栗子-破产案件一体化管理平台")]),_c('a',[_c('i',{staticClass:"icon icon-link",attrs:{"href":"https://www.polz.cn"}}),_vm._v("www.polz.cn")])])}]

export { render, staticRenderFns }