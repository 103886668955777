
































































































































































































































































































































































































































import { Mixins , Component } from 'vue-property-decorator';
import { Form , Field , Cell , Popup , Picker , Button , Uploader , Icon , Toast , CheckboxGroup , Checkbox , DatetimePicker, Cascader  } from 'vant';
import CompressImage from '@/mixins/CompressImage';
import { fileData} from '@/core/utils';
import SendVerifyCode from '@/components/SendVerifyCode.vue';
import BankAgreementDialog from '@/components/BankAgreementDialog.vue';
import { user  } from '@/core/utils.ts';
import wx from "weixin-js-sdk";
@Component({
    components:{
      'van-form' : Form ,
      'van-field' : Field ,
      'van-cell' : Cell ,
      'van-picker' : Picker ,
      'van-popup' : Popup ,
      'van-button' : Button ,
      'van-uploader' : Uploader,
      'van-icon' : Icon ,
      'van-checkbox-group' : CheckboxGroup ,
      'van-date-picker': DatetimePicker ,
      'van-checkbox' : Checkbox ,
      'van-cascader' : Cascader ,
      'send-verify-code': SendVerifyCode,
      'bank-agreement-dialog' : BankAgreementDialog,
    }
})
export default class FundsElectronicAccount extends Mixins(CompressImage) {
  //人脸识别数据ID
  private face_id:any=null;
  //人脸验证结果
  private face_result:boolean = false;    // true:成功；fasle:失败；
  //获取人脸数据ID
  private onFace(){
    Toast.loading({
      message: '正在打开...',
      forbidClick: true,
      duration:0,
    })
    let detail = this.filterParams();
    // console.log(detail)
    this.axios.post(this.$URL.ADD_FACE_LOG , { name:detail.UserName , card:detail.IdNo , type:3 , input_data: JSON.stringify(detail)}).then((res:any)=>{
      console.log( res , this.face_id , '获取人脸ID')
      if(res.code == 1){
        if( !this.face_id ){
          this.face_id = res.data.id
        }
        Toast.clear();
        wx.miniProgram.navigateTo({ url: `/pages/face_medium/index?id=${this.face_id}` })    //跳转到人脸小程序
      }
    })
  }


    //上传图片文件列表
    private idCard_list:any={
      frontImg:[],
      backImg:[],
      bankCardImg:[]
    };

    private params:any={
      case_num:'',
      id:'',
      step: 1 ,
      frontImg:'' ,
      backImg:'' ,
      UserName:'',
      Gender:{
        id:undefined,
        name:''
      },
      IdNo:'',
      BankCardNo:'',
      People:{
        id:undefined,
        code:'',
        name:''
      },
      Address:null,
      detail_address:'',      //详细地址
      CertStatDate:null,
      CertEndDate:null,

      bank:'',
      MobilePhone:'',
      password:'',
      new_pwd:'',
      sms_code:'',
      is_check : false,

      face_img:'',          //人脸影像；
    }


  // 上传文件
  private uploadCardImg(file:any){
    return new Promise((resolve:any)=>{
      this.$uploadFile.post(this.$URL.UPLOAD_FILE, fileData(file.file)).then((res:any)=>{
        if(res.code == 1){
          // console.log(res.data.src, ' 图片地址')
          resolve( this.$URL.BASE_URL + res.data.src)
        }
      })
    })
  }

  //将图片路径转换成base64
  private getBase64( img:any ){
      return new Promise((resolve:any)=>{
        let reader = new FileReader();
        // let imgBase64:any ;
        if(img){
          reader.readAsDataURL(img);
          reader.onload = (e:any)=>{
            // cosnole.log(reader.result )
            resolve(e.target.result.split(',')[1])
          };
        }
      })

  }

    // Ocr识别身份证
    private onOcrImage(){
      return new Promise((resolve:any )=>{
        this.$noSign.post(this.$URL.IDCARD_OCR , { backImg : this.params.backImg , frontImg:this.params.frontImg }).then((res:any)=>{
          // console.log(res , this.params , 'ocr结果')
          let data = res.data.data
          if( res.data.code == 1){
            resolve(data)
          }else{
            Toast.fail({
              message: '识别失败！',
              forbidClick: true,
            });
          }
        })
      })
    }
    //ocr识别银行卡
    private onOcrBankCard( url:string ){
        return new Promise((resolve:any)=>{
          this.$noSign.post(this.$URL.BANK_CARD_OCR , { Img:url} ).then((res:any)=>{
            // console.log(res , '银行卡OCR结果')
            if(res.data.code == 1){
              resolve(res.data.data)
            }else{
              Toast.clear();
              Toast.fail({
                message:'卡号识别失败，请手动输入卡号',
                forbidClick:true
              })
            }
          })
        })
    }

    // OCR
    private async onIdCardOcr( file:any , info:any ){
      if( !this.params.frontImg && !this.params.backImg ){
        this.params[info.name] = await this.getBase64( file.file )
        // console.log(this.params , 'params')
        return
      }else{
        Toast.loading({
          message: '上传中...',
          forbidClick: true,
          duration:0,
        });
        this.params[info.name] = await this.getBase64(file.file);
        // console.log(this.params , 'params')
        let data:any = await this.onOcrImage();

        // this.params.card_face = imageUrl;

        this.params.Address = data.address;
        this.params.IdNo = data.idNo;
        this.params.UserName = data.customerName;
        if(data.sex == '02'){
          this.params.Gender = { id : 1 , name:'男' }
        }else{
          this.params.Gender = { id : 2 , name:'女' }
        }
        this.params.CertStatDate = data.certEffectDate;
        this.params.CertEndDate = data.certInvalidDate;
        // this.onPeopleCode( data.Nation );
        for(let k in this.nationOptions){
          if(this.nationOptions[k].id == data.ethnicity ){
            this.params.People = this.nationOptions[k]
          }
        }
        this.$nextTick(()=>{
          Toast.clear();
        })
      }
    }
    //OCR识别银行卡
    private async onIdCardOcr_3( file:any){
      Toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration:0,
      });
      let imgUrl:any = await this.getBase64(file.file);
      let data:any = await  this.onOcrBankCard(imgUrl);
      // console.log(data)
      if( data ){
        this.params.BankCardNo = data.cardNo;
        this.params.bank = data.bankName;
      }
        this.$nextTick(()=>{
          Toast.clear();
        })

    }

    // 性别选项 -- 弃用
    private genderOptions:any = [
      {name:'男' , id:1 } , { name:'女' , id:2 }
    ];
    private genderShow:boolean=false;
    // 民族选项
    private nationOptions:any=[];
    private nationShow:boolean=false;
    private getNationOption(){
      this.axios.post(this.$URL.NATION_LIST).then((res:any)=>{
        // console.log(res,'民族列表')
        if(res.code == 1){
          for(let k of res.data.list){
            this.nationOptions.push(k)
          }
        }
      })
    }

  //选择地址 -- 弃用
  private siteShow:boolean = false;
  private site_options:any=[];
  private siteChange( val?:any ){
    this.axios.post(this.$URL.AREA_LIST , { areaId : val?val.value:'' }).then((res:any)=>{
      if(res.code == 1 && res.data.length > 1){
        if(!val){
          for(let k in res.data){
            res.data[k].children = []
          }
          this.site_options = JSON.parse(JSON.stringify(res.data));
        }else{
            if( val.tabIndex == 0){
              for( let j in res.data){
                res.data[j].areaId = String(res.data[j].areaId)
                res.data[j].children = []
              }
              let first_index = this.site_options.findIndex((item:any)=>{ return item.areaId === val.selectedOptions[0].areaId })
              this.site_options[first_index].children = JSON.parse(JSON.stringify(res.data))
            }
            if(val.tabIndex == 1){
              let first_index = this.site_options.findIndex((item:any)=>{ return item.areaId === val.selectedOptions[0].areaId })
              let sec_index = this.site_options[first_index].children.findIndex(( item:any )=>{ return item.areaId === val.selectedOptions[1].areaId })
              this.site_options[first_index].children[sec_index].children = JSON.parse(JSON.stringify(res.data))
            }
        }
      }
    })
  }
  private siteFinish( val:any ){
    this.params.Address = val.selectedOptions.map((item:any)=>{ return item.areaName }).join('');
    this.siteShow = false
  }

    //日期选项--弃用
  private starDateShow:boolean=false;
  private getStarDate(e:any){
    let date = new Date(e);
    const year = date.getFullYear();
    const month = date.getMonth()+1;
    const day = date.getDate();
    this.params.CertStatDate = year+'/' + (month<10?'0'+month:month) + '/' + ( day<10?'0'+day:day);
    this.$nextTick(()=>{ this.starDateShow = false})
    // console.log(this.params.CertStatDate )
  }
  private endDateShow:boolean = false;
  private getEndDate(e:any){
    let date = new Date(e);
    const year = date.getFullYear();
    const month = date.getMonth()+1;
    const day = date.getDate();
    this.params.CertEndDate = year+'/' + (month<10?'0'+month:month) + '/' + ( day<10?'0'+day:day);
    this.$nextTick(()=>{ this.endDateShow = false})
    // console.log(this.params.CertEndDate )
  }


    // 职业选项--弃用
    private jobOption:any=[];
    private jobShow:boolean = false;
    private getJobOption(){
      this.axios.post(this.$URL.FUNDS_JOB_LIST).then((res:any)=>{
        if(res.code ==1 ){
          for(let k of res.data.list){
            this.jobOption.push(k)
          }
        }
      })
    }


    // 查看用户协议
    private onBnakDialog(){
      (this.$refs.bank_agreement_dialog as any).show = true;
    }

    // 获取银行名称
    private getBankName(){
      this.axios.post(this.$URL.BANK_NAME , {num:this.params.BankCardNo}).then((res:any)=>{
        if(res.code == 1){
          this.params.bank = res.data.list[0].bank_name
        }
      })
    }

    //获取民族代码
    private onPeopleCode(name:string){
      // console.log(name)
      this.axios.post(this.$URL.NATION_CODE , { name: name }).then((res:any)=>{
        // console.log(res , '，民族代码')
        if(res.code == 1){
          for(let k in res.data.list){
            this.params.People[k] = res.data.list[k]
          }
        }
      })
    }

    //筛选参数
    private filterParams(){
      let data:any={};
      Object.assign(data,this.params);
      data.Gender = this.params.Gender.id;
      data.People = this.params.People.code;
      data.card_face = this.params.frontImg;
      data.card_back = this.params.backImg;
      data.id = user.get('id');
      return data
    }


    // 下一步
    onResult(){
    //填写第一页的表单
      if(this.params.step == 1){
        let form = (this.$refs.electronic_form as any)
        form.validate().then(()=>{
          this.params.step = 2;
        })
      }else{  //填写的第二页的表单
        let form = (this.$refs.check_form as any)
        form.validate().then(()=>{
          if(!this.params.is_check ){
            Toast.fail({
              message: '请勾选服务协议!',
              forbidClick: true,
            });
          }else{
            Toast.loading({
              message: '上传中...',
              forbidClick: true,
              duration:0,
            });
            let data:any={};
            Object.assign(data , this.params )
            for( let k of ['case_num'  , 'frontImg' , 'backImg' , 'new_pwd' , 'is_check']){
              delete data[k]
            }
            // console.log(data)
            //提交表单数据
            this.axios.post(this.$URL.FUNDS_ADD_BANK , { ...data } ).then((res:any)=>{
              let info = JSON.parse(res);
              // console.log(info)
              if(info.returnCode == '000000'){
                this.$router.push({
                  path:'/funds/electronic_result',
                  query:{
                    result:'1',
                    UserName: info.data.UserName ,
                    MobilePhone: info.data.MobilePhone ,
                    AcNo : info.data.AcNo ,
                    returnCode : info.returnCode
                  }
                })
                Toast.clear();
              }else{
                this.$router.push({
                  path:'/funds/electronic_result',
                  query:{
                    result:'0',
                    returnMsg:info.returnMsg,
                    returnCode : info.returnCode
                  }
                })
                Toast.clear();
              }
            }).catch((err:any)=>{
              this.$toast.fail(err.message)
            })
          }
        })
      }
    }


  private onSubmit(){

  }

  //重新加载已保存的信息
  private getFormDetail( id: any){
    Toast.loading({
      message:'正在加载...',
      forbidClick: true,
      duration:0,
    })
    // console.log(id)
    this.$noSign.post(this.$URL.FACE_RESULT , { id : id } ).then((res:any)=>{
      // console.log(res , id  , '加载')
      Toast.clear()
      let data = res.data.data.detail;
      const detail = JSON.parse(data.input_data)
      for(let k in detail){
        this.params[k] = detail[k]
      }
      this.params.face_img = data.photo
    //  判断是否人脸验证成功
      if(res.data.code == 1){
        this.face_result = true ;
      }else {
        this.face_result = false ;
      }
    }).catch(()=>{
      Toast.clear()
      Toast.fail({
        message:'Load failure！'
      })
    })
  }

  created(){
    let href = window.location.href.split('?')[1]||'';
    let data:any = {};
    href.split('&').forEach((item:any)=>{
      let key = item.split('=');
      data[key[0]] = key[1]
    })
    if(data && data.face_id ){
      // console.log(data)
      this.getFormDetail( data.face_id );
    }else {
      // this.onPeopleCode('汉族');
      this.params.MobilePhone = user.get('mobile');
      this.params.case_num = user.get('case_num');
    }
    this.getNationOption();
    this.getJobOption();
    this.siteChange();
  }
}
