
























import { Vue, Component } from 'vue-property-decorator';
import Back from "@/components/Back.vue";

@Component({
  components:{
    back:Back

  }
})
export default class NewsDetails extends Vue {
    //详情
    detail: any = {};

    //获取详情
    getDetail(id: number) {
        this.axios.post(this.$URL.MESSAGE_DETAIL, { id }).then((res: any) => {
            if (res.code == 1) {
                this.detail = res.data.details;
            } else {
                this.$notify(res.message);
            }
        });
    }

    created() {
        this.getDetail(Number(this.$route.query.id));
    }
}
