






















import { Vue, Component } from 'vue-property-decorator';
import Back from "@/components/Back.vue";

@Component({
  components:{
    back:Back

  }
})
export default class RegisterSuccess extends Vue {
    //读秒
    second: number = 0;
    
    //倒计时
    countDown(initSecond: number = 3) {
        this.second = initSecond;
        this.$nextTick(() => {
            const timer = setInterval(() => {
                --this.second;
                if (this.second <= 0) {
                    clearInterval(timer);
                    this.$router.replace('/login');
                }
            }, 1000);
        });
    }

    mounted() {
        this.countDown();
    }
}
