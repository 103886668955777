/*
    压缩图像
*/

import { Vue, Component } from 'vue-property-decorator';
import { compressImg } from '@/core/utils';

@Component
export default class CompressImage extends Vue {

    //图像文件转换URL
    public imageFileToUrl(file: File): string | undefined {
        if (/^image/.test(file.type)) {
            return (window.URL || window.webkitURL || window).createObjectURL(file);
        }
        return undefined;
    }

    //监听压缩图像
    public onCompressImage(file: File) {
        const url = this.imageFileToUrl(file);
        let quality = 1.0;
        const size = file.size;
        if (size > 2097152) {
            quality = 0.2;
        } else if (size > 1048576) {
            quality = 0.5;
        }
        if (url && quality < 1.0) {
            return compressImg(url, 'image.jpg', quality);
        } else {
            return Promise.resolve(file);
        }
    }
}