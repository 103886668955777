









import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Conference extends Vue {
	//启用
	private enabled: boolean = false;

	//路由
	private router: string = '';

	//加入
	public join(config: any) {
		this.router = (this.$router as any).history.base + '/conference/';
		(window as any).pageConfig = config;
		(window as any).pageConfig['meetingRouter'] = this.router;
		this.enabled = true;
	}

	destroyed() {
		(window as any).pageConfig = undefined;
	}
}
