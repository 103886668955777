







































































import { Vue, Component } from 'vue-property-decorator';
import { Tab, Tabs } from 'vant';
import wx from 'weixin-js-sdk';
import Back from "@/components/Back.vue";

@Component({
    components: {
        'van-tab': Tab,
        'van-tabs': Tabs,
      back:Back

    }
})
export default class NoticeDetails extends Vue {
    //标签索引
    public tabActive: number = 0;

    //详情
    detail: any = {};

    //获取详情
    getDetail(id: number) {
        this.axios.post(this.$URL.NOTICE_DETAILS, { id }).then((res: any) => {
            if (res.code == 1) {
                this.detail = res.data.info;
            } else {
                this.$notify(res.message);
            }
        });
    }
	
	open_file(url:string){
		wx.miniProgram.navigateTo({url: '/pages/open_file/open_file?path=' + url})
	}

    created() {
        this.getDetail(Number(this.$route.query.id));
    }
}
