import User from '@/core/User';
import Secret from '@/core/Secret';

//用户数据
const user = new User('creditor_h5_user_data');
const system_info = new User('system_info');
const bank_account = new User('bank_account');
const face_info = new User('face_info');

//签名钥匙
const signKey: string = 'YdFwDEHE8Xe9uxj7';
//rsa公钥
const rsaPublicKey: string = '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCm5ZAbtpg7Emmvc5BhaXDobviqc2lr3vxukl/iV8mFXG+PsXN/su5pDPPQLkhJ1yHByVTOAwPPViLr6qriS9zP2FK3PtONmnTxWNlKNXNOkvf89RNr+Da1OVJ1I5nM8evOk3R/Lb8YUSYC+Ii5H66URLPwVfV+3Cwp8aEHHgLswQIDAQAB-----END PUBLIC KEY-----';
//rsa私钥
const rsaPrivateKey: string = '-----BEGIN RSA PRIVATE KEY-----MIICWwIBAAKBgQCm5ZAbtpg7Emmvc5BhaXDobviqc2lr3vxukl/iV8mFXG+PsXN/su5pDPPQLkhJ1yHByVTOAwPPViLr6qriS9zP2FK3PtONmnTxWNlKNXNOkvf89RNr+Da1OVJ1I5nM8evOk3R/Lb8YUSYC+Ii5H66URLPwVfV+3Cwp8aEHHgLswQIDAQABAoGASEhkgZwzkmIHI9YJUic4n3JwgGI75lxNOBZtMLqUrQ27w6A3RbdZSmYxsphY9qIWHLf14sOrgsDrnwGjwR7aIvcwJjN8Kn8hRbFGSPbkeZY+YaTDODkbrjo2bKFGeSvQlM7tOBGX5QfI2ahEq+oO9Ta18s/fXU68KcsfmvSOw4ECQQDdxvzmygvX41slnH9uWcz9mYlSdNCGtzlaa1JqKyNj337JjNfaN/1e3szJU0ZawJ6Bvf7Qj5rd1g+++BCQafIHAkEAwKaXrXIAytUUKCmByLXJNQtnfDCsN06kXiOuxtMmXAewMKII42bdeYM677qbCtH4mLj1zDuiWeK5clvZOKhY9wJALtn70XG7oMcKGV7/WLul36lQ5leBOgR5HE3x4jXsywuLCF81tob/gsxErP9nrIJmuff+XecIi3bOasSTEz8efwJAfGubTiNwq47OA9o6vbvaBO4mmah9sQKTWVFGUZaREaLNgbtEbklDBHVinO0xsN1G5qud45coFVE+X8M+GT9wpQJAafPjWf5N73LkesRgqbZju+NXkMEmKwoqATfOWrUizLADhb7o3T91CdLnuTf6oJGsOAW8auunRdSwUlk8w0oP/g==-----END RSA PRIVATE KEY-----';
//数据加密
const secret = new Secret(signKey, rsaPublicKey, rsaPrivateKey);

//文件数据
function fileData(file: File | Blob, name: string = 'file'): FormData {
    let data = new FormData();
    if (file instanceof File) {
        data.append(name, file);
    } else {
        const fileName = Date.now() + '.' + file.type.split('/')[1];
        // console.log(fileName)
        data.append(name, file, fileName);
    }
    // console.log(file)
    return data;
}

//转换文件
function dataURLtoFile(dataUrl: string, name: string = '') {
    let arr: any = dataUrl.split(',');
    const mime: any = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], name, { type: mime });
}

//压缩图片
function compressImg(dataUrl: string, name: string = '', quality: number = 1){
    return new Promise(function(resolve) {
        let img: any = new Image();
        img.src = dataUrl;
        img.onload = function() {
            const { width, height } = this;
            let canvas = document.createElement('canvas');
            let ctx: any = canvas.getContext('2d');
            canvas.setAttribute("width", width);
            canvas.setAttribute("height", height);
            ctx.drawImage(this, 0, 0, width, height);
            resolve(dataURLtoFile(canvas.toDataURL('image/jpeg', quality), name));
        };
    });
}

//字符串转换数值数组
function strToNumArray(strArr: string, defArr: number[] = []): number[] {
    if (strArr) {
        return strArr.split(',').map(strNum => Number(strNum));
    }
    return defArr;
}

//复制文本
function copyText(text: string) {
    const input: HTMLInputElement = document.createElement('input');
    input.value = text;
    input.style.position = 'fixed';
    input.style.opacity = '0';
    document.body.appendChild(input);
    input.select();
    document.execCommand("Copy");
    document.body.removeChild(input);
}

/*数字补零*/
function numFillZero(num: number): string {
    return (num > 9 ? num : '0' + num).toString();
}

/*日期格式化*/
function dateFormat(date: Date, format: string): string {
    return format.replace('yyyy', date.getFullYear().toString())
        .replace('MM', numFillZero(date.getMonth() + 1))
        .replace('dd', numFillZero( date.getDate()) )
        .replace('hh', numFillZero( date.getHours()) )
        .replace('mm', numFillZero( date.getMinutes()) )
        .replace('ss', numFillZero( date.getSeconds()) );
}

export {
    user,
    system_info,
    bank_account,
    face_info,
    secret,
    fileData,
    copyText,
    strToNumArray,
    compressImg,
    dateFormat
};

//声明全局
declare module "vue/types/vue" {
    interface Vue {
        $user: User,
    }
}