





























import { Component, Vue, Prop } from 'vue-property-decorator';

/* 消息 */
interface Message {
    name: string;
    text: string;
}

@Component
export default class Chat extends Vue {
  //即时通信
    private ws!: WebSocket;
    
    //是否载入
    private isTimReady: boolean = false;

    //昵称
    private nickname: string = '';

    //群组ID
    private groudId!: number;

    //发送内容
    private content: string = '';

    //消息列表最大长度
    @Prop({
        type: Number,
        default: 3000
    })
    public maxLength!: number;

    //消息列表
    private messageList: any = [];

    //禁言状态
    public prohibitToSpeakStatus: string = '1';

    //滚动底部
    public rollingBottom() {
      this.$nextTick(() => {
        const scrollbar = this.$refs.scrollbar as any;
        scrollbar.scrollTop = scrollbar.scrollHeight;
      });
    }

    //添加消息列表
    public appendMessage(message: Message) {
        this.messageList.push(message);
        if (this.messageList.length > this.maxLength) {
            this.messageList.shift();
        }
        this.rollingBottom();
    }

    //心跳检测
    private pingTimer!: number;

    public startPing() {
        this.pingTimer = setInterval(() => {
            this.ws.send('{"type": "ping"}');
        }, 55 * 1000);
    }

    public stopPing() {
        clearInterval(this.pingTimer);
    }

    //加入群组
    public joinGroup(gid: number, nickname: string) {
        this.ws = new WebSocket('wss://wss.polz.hk?group_id=' + gid);
        this.groudId = gid;
        this.nickname = nickname;

        //打开连接
        this.ws.onopen = () => {
            this.startPing();
            this.isTimReady = true;
            this.reqProhibitToSpeakStatus();
        };

        this.ws.onmessage = this.receiveMessage.bind(this);

        //关闭连接
        this.ws.onclose = () => {
            this.stopPing();
        };
    }

    //发送消息
    public sendMessage(content: string) {
        if (this.content != '') {
            const message = JSON.stringify({
                type: 'send_to_group',
                group: this.groudId,
                nickname: this.nickname,
                content
            });
            this.ws.send(message);
            this.content = '';
        }
    }

    private onEnter(event: any) {
        if (this.isTimReady && event.key === "Enter") {
            this.sendMessage(this.content);
            setTimeout(() => {
                this.content = '';
            }, 200);
        }
    }

    //请求禁言状态
    public reqProhibitToSpeakStatus() {
      const message = JSON.stringify({
        type: 'get_prohibit_to_speak_status',
        group: this.groudId
      });
      this.ws.send(message);
    }

    //接收消息
    public receiveMessage(evt: MessageEvent) {
      const message = JSON.parse(evt.data);
      if (message.type == 'send_to_group') {
        this.appendMessage({
          name: message.nickname,
          text: message.content
        });
      } else if (message.type == 'say_status') {
        this.prohibitToSpeakStatus = message.status;
      }
    }

    //离开群组
    public leaveGroup() {
        if (this.ws) {
            this.ws.send('{"type": "leave_group"}');
            this.ws.close();
        }
    }

    destroyed() {
        this.leaveGroup();
    }
}
