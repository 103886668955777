/*
    网络请求
*/

import axios from 'axios';
import store from '@/store';
import Secret from '@/core/Secret';
import { URL } from '@/axios/URL';
import { user, secret } from '@/core/utils';
import { Toast } from 'vant';

//请求基地址
axios.defaults.baseURL = URL.BASE;

//请求令牌
axios.defaults.headers.common['token'] = user.get('token');
user.on('token', function(token: string) {
    axios.defaults.headers.common['token'] = token;
});

//拦截请求加密
axios.interceptors.request.use((config) => {
    // console.log(config.url);
    // console.table(config.data);

    config.data = secret.encrypt(config.data);
    return config;
});

//拦截响应解密
axios.interceptors.response.use((res): any => {
    let resData = res.data;
    if (resData.data && Secret.isEncryptResult(resData.data)) {
        resData.data = secret.decrypt(resData.data) || {};
    }

    // 登录过期
    if (resData.code == -3) {
        Toast('登录过期，请重新登录')
        store.commit('account/exitLogin');
    }

    //console.log(res.config.url);
    //console.table(resData);

    return resData;
}, (error): any => {
    Toast.fail("网络连接失败");
    return Promise.reject(error);
});

export default axios;
export { URL } from './URL';
export { download } from './download';
export { uploadFile } from './uploadFile';
export { noSign } from './noSign';