














































import { Component, Mixins } from 'vue-property-decorator';
import EmptyField from '@/mixins/EmptyField';
import { Checkbox, Form, Field, Button } from 'vant';
import { user  } from '@/core/utils.ts';
import wx from 'weixin-js-sdk';
import Back from "@/components/Back.vue";

@Component({
    components: {
        'van-form': Form,
        'van-field': Field,
        'van-button': Button,
        'van-checkbox': Checkbox,
      back:Back

    }
})
export default class GetUserCard extends Mixins(EmptyField) {
    //请求字段
    fields: any = {
        card:'',
        id: 0,
    };
    // 账号的姓名
    private account_name:string='';

    private submit(){
        this.axios.post(this.$URL.ADD_FACE_LOG , { type:2 , name:this.account_name , ...this.fields}).then((res:any)=>{
            // console.log('获取人脸ID' , res , this.account_name , this.fields.card)
            if(res.code === 1){
          wx.miniProgram.navigateTo({ url: '/pages/scanLogin/scanLogin?name='+ res.data.id })

            }
        })
    }
	created(){
        this.account_name = user.get('name') ; 
	}
}
