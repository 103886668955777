/*
    规则
*/

//数据格式
export namespace FORMAT {
    export const NOT_NULL = /^.+$/;     //不为空
    export const NUMBER = /^\d+$/;      //数值
    export const VCODE = /^\d+$/;     //验证码
    export const MOBILE = /^\d{11}$/;   //手机号码
    export const CONTACT = /^(\d{11}|\d{7,8})$/;//联系方式
    export const NAME = /^[\u4E00-\u9FA5][\u4E00-\u9FA5·]{0,18}[\u4E00-\u9FA5]$/;   //姓名
    export const PASSWORD = /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d$@$!%*?&]{6,15}$/;     //密码
    export const CREDIT_CODE = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/i;  //社会信用代码
    export const IMAGE_FILE_TYPE = /^(jpeg|png|webp)$/;  //图像类型
    export const TABLE_FILE_TYPE = /(xlsx|xls)$/; //表格文件
    export const URL = /^(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]$/;   //URL地址
    export const PDF_FILE_TYPE = /pdf$/;   //PDF
    export const ID_CARD = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;    //身份证号码
    export const BANK_CARD = /^[1-9]\d{12,}$/;    //银行卡号码
}

//测试规则
export interface Rule {
    name: string,
    format: RegExp | Function,
    message: string
}

//提交数据
interface Data {
    [name: string]: string | number
}

export default class Rules {

    //格式规则
    private _ruleMap: Rule[];

    constructor(rules: Rule[] = []) {
        this._ruleMap = rules;
    } 

    //加入规则
    public join(name: string, format: RegExp | Function, message: string): void {
        this._ruleMap.push({
            name, format, message
        });
    }

    //测试规则
    public test(data: Data, callback: (rule: Rule) => void): boolean {
        let isPass = true;
        for (let k in data) {
            for (let rule of this._ruleMap) {
                if (rule.name == k) {
                    if (rule.format instanceof RegExp && !rule.format.test(data[k].toString())) {
                        isPass = false;
                        callback(rule);
                        break;
                    }
                    if (rule.format instanceof Function && !rule.format(data[k])) {
                        isPass = false;
                        callback(rule);
                        break;
                    }
                }
            }
        }
        return isPass;
    }
}

export { Rules };