







































































import { Vue, Component } from 'vue-property-decorator';
import { Tabbar, TabbarItem } from 'vant';
import { namespace } from 'vuex-class';

const account = namespace('account');

@Component({
    components: {
        'van-tabbar': Tabbar,
        'van-tabbar-item': TabbarItem
    }
})
export default class My extends Vue {
    //标签索引
    active: number = 2;

    //头像
    @account.State('profile')
    avatar!: string;
}
