
















































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Back from "@/components/Back.vue";

const account = namespace('account');

@Component({
  components:{
    back:Back

  }
})
export default class UserInfo extends Vue {
    //姓名
    @account.State('name')
    name!: string;

    //手机号码
    @account.State('mobile')
    mobile!: string;

    //债权信息
    creditorInfo: any = {
        card_type_val: '',
        card_num: '',
        address: ''
    };

    //获取债权信息
    getCreditorInfo() {
        this.axios.post(this.$URL.GET_BASE_INFO).then((res: any) => {
			console.log(res)
            if (res.code == 1 && res.data.info) {
                const { card_type_val, card_num, address } = res.data.info;
                this.creditorInfo.card_type_val = card_type_val;
                this.creditorInfo.card_num = card_num;
                this.creditorInfo.address = address;
            }
        });
    }

    created() {
        this.$store.dispatch('account/getInfo');
        this.getCreditorInfo();
    }
}
