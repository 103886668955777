

















































































































































import {Component, Vue} from "vue-property-decorator";
import Back from "@/components/Back.vue";
import { Form , Field , Cell , Popup , Picker , Button , Uploader , Icon ,Toast  , CheckboxGroup , Checkbox , DatetimePicker, Cascader  } from 'vant';


@Component({
  components:{
          back:Back,
          'van-form' : Form ,
      'van-field' : Field ,
      'van-cell' : Cell ,
      'van-picker' : Picker ,
      'van-popup' : Popup ,
      'van-button' : Button ,
      'van-uploader' : Uploader,
      'van-icon' : Icon ,
      'van-checkbox-group' : CheckboxGroup ,
      'van-date-picker': DatetimePicker ,
      'van-checkbox' : Checkbox ,
      'van-cascader' : Cascader ,
  }
})
export default class CollectCreditorInfo extends Vue{

  private loading:boolean= false;

  private params:any={
    first_name:'',
    last_name:'',
    contacts:'',
    title:'',
    residence:'',
    intel_area_code:'',
  }


  private TitleShow:boolean = false;
  private jobOption:any=[
        { name:'Miss' },
        { name:'Ms.' },
        { name:'Mrs.' },
        { name:'Mr.' },
        { name:'Dr.' },
      ]

  private AreaCodeShow:boolean = false;
  private phoneList:any=[];
  private area_code:string='';


  created(){
    this.getCreditorInfo();
    this.axios.post(this.$URL.INTEL_AREA_CODE_LIST ).then((res:any)=>{
      console.log(res )
      if(res.code == 1){
        this.phoneList =res.data.list
      }
      for(let k in this.phoneList){
        if( this.phoneList[k].id == this.params.intel_area_code){
          this.area_code = this.phoneList[k].title + this.phoneList[k].area_code
        }
      }
    })
  }

  private getCreditorInfo(){
    this.loading = true;
    this.axios.post(this.$URL.CREDITOR_PERSONAL_INFO_DETAIL).then((res:any)=>{
      console.log(res , '债权人信息详情');
      this.loading = false
      if(res.code ==1 && res.data.list ){
        for(let k in res.data.list){
          this.params[k] = res.data.list[k]
        }
      }
    })
  }

  private onSubmit(){
    let data:any={};
    Object.assign(data , this.params );
    console.log( data , '提交的data' );
    this.axios.post( this.$URL.CREDITOR_PERSONAL_INFO_REPORT , data ).then((res:any)=>{
      console.log(res, '提交')
      if(res.code == 1){
        Toast.success('提交成功');
        this.$router.push({ path:'/' , query:{} })
      }
    });
  }


  private onResult(){

  }

  private getBankName(){

  }
}
