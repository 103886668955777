



































































































import { Component, Mixins } from 'vue-property-decorator';
import EmptyField from '@/mixins/EmptyField';
import { Checkbox, Form, Field, Button } from 'vant';
import SendVerifyCode from '@/components/SendVerifyCode.vue';
import { system_info } from '@/core/utils.ts';
import Back from '@/components/Back.vue';


@Component({
    components: {
        'van-form': Form,
        'van-field': Field,
        'van-button': Button,
        'van-checkbox': Checkbox,
        'send-verify-code': SendVerifyCode,
      back:Back
    }
})
export default class ForgetPassword extends Mixins(EmptyField) {
    //请求字段
    fields: any = {
        mobile: '',
        sms_code: '',
        password: '',
		plat_id:''
    };
    //确认密码
    again_password: string = '';

    //设置密码
    setPassword(fields: any) {
        this.$toast.loading({
            message: "正在修改",
            forbidClick: true,
            duration: 0
        });
		// console.log(this.fields)
        this.axios.post(this.$URL.SET_PASSWORD, fields).then((res: any) => {
            if (res.code == 1) {
                this.$toast.success("success");
                this.$router.replace('/login');
            } else {
                this.$toast.fail(res.message);
            }
        });
    }

    //监听设置密码
    onSetPassword() {
        this.setPassword(this.fields);
    }
	
	public system_info:any = {};
	created(){
		this.system_info = system_info.getBetch( [ 'logo' , 'name' , 'id' ]) ;
		this.fields.plat_id = this.system_info.id ;
	}
}
