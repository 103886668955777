






































import { Component, Vue, Prop } from 'vue-property-decorator';
import { dateFormat } from '@/core/utils';

@Component
export default class Message extends Vue {
	//即时通信
    private ws!: WebSocket;
    
    //是否载入
    private isTimReady: boolean = false;

    //昵称
    private nickname: string = '';

    //群组ID
    private groudId!: number;

    //发送内容
    private content: string = '';

    //消息列表最大长度
    @Prop({
        type: Number,
        default: 3000
    })
    public maxLength!: number;

    //问题列表
    private issuesList: any = [];

    //禁言状态
    public prohibitStatus: string = '1';

    //滚动底部
    public rollingBottom() {
        this.$nextTick(() => {
           const scrollbar = this.$refs.scrollbar as any;
           scrollbar.scrollTop = scrollbar.scrollHeight;
        });
    }

    //心跳检测
    private pingTimer!: number;

    public startPing() {
        this.pingTimer = setInterval(() => {
            this.ws.send('{"type": "ping"}');
        }, 55 * 1000);
    }

    public stopPing() {
        clearInterval(this.pingTimer);
    }

    //加入群组
    public joinGroup(gid: number, nickname: string) {
        this.ws = new WebSocket('wss://wss.polz.hk?group_id=' + gid);
        this.groudId = gid;
        this.nickname = nickname;

        // console.log( this.ws , '11231')

        //打开连接
        this.ws.onopen = () => {
            this.startPing();
            this.isTimReady = true;
            this.reqProhibitStatus();
        };

        this.ws.onmessage = this.receiveMessage.bind(this);

        //关闭连接
        this.ws.onclose = () => {
            this.stopPing();
        };

        this.loadIssuesList();
    }

    //请求禁止状态
    public reqProhibitStatus() {
        const message = JSON.stringify({
            type: 'get_message_to_status',
            group: this.groudId
        });
        this.ws.send(message);
        // console.log(message , '123123')
    }

    //接收消息
    public receiveMessage(evt: MessageEvent) {
        const message = JSON.parse(evt.data);
        if (message.type == 'message_status') {
            this.prohibitStatus = message.status;
        }
    }

    //离开群组
    public leaveGroup() {
        if (this.ws) {
            this.ws.send('{"type": "leave_group"}');
            this.ws.close();
        }
    }

    //加载问题列表
    public loadIssuesList() {
        this.axios.post(this.$URL.MEETING_ISSUES_LIST, {
            meeting_id: this.groudId
        }).then((res: any) => {
            if (res.code == 1) {
                this.issuesList = res.data.list;
                this.rollingBottom();
            }
        });
    }

    //提交留言问题
    public submitIssues(message: string) {
        this.axios.post(this.$URL.MEETING_SUBMIT_ISSUES, {
            meeting_id: this.groudId,
            message
        }).then((res: any) => {
            if (res.code == 1) {
                this.issuesList.push({
                    name: this.nickname,
                    message,
                    create_time: dateFormat(new Date, 'hh:mm MM/dd/yyyy')
                });
                this.content = '';
                this.rollingBottom();
            }
        });
    }

    destroyed() {
        this.leaveGroup();
    }
}
