












import { Component, Prop, Vue } from 'vue-property-decorator';
import Rules from '@/core/Rules';
import { FORMAT } from '@/core/Rules';
@Component
export default class SendVerifyCode extends Vue {
    //手机号码
    @Prop({
        type: String,
        required: true
    })
    public mobile!: string;

    //平台id
    @Prop({
      type:Number,
    })
    public plat_id!:number

    //事件名称
    @Prop({
        type: String,
        required: true
    })
    public event!: string;

    //请求地址
    @Prop({
        type: String
    })
    public url!: string;

    //间隔时间
    @Prop({
        type: Number,
        default: 60
    })
    public delay!: number;

    //请求参数
    @Prop({
        type: Object,
        default: ()=>{ return {} }
    })
    public params!: any;

    //秒
    private second: number = 0;

    //倒计时
    private countDown(delay: number) {
        this.second = delay
        if (delay > 0) {
            setTimeout(() => {
                this.countDown(delay - 1);
            }, 1000);
        }
    }

    //发送
    private onSend() {
        const rules = new Rules();
        rules.join('mobile', FORMAT.NOT_NULL, "Please enter your mobile number");
        rules.join('mobile', FORMAT.MOBILE, "请输入正确的手机号码");
        if (rules.test({ mobile: this.mobile }, (rule) => {
            this.$emit('fail', rule.message);
        })) {
            this.axios.post(this.url || this.$URL.SEND_VERIFY_CODE, {
                mobile: this.mobile,
                event: this.event,
                plat_id: this.plat_id,
                ...this.params
            }).then((res: any) => {
                if (res.code == 1) {
                    this.countDown(this.delay);
                    this.$emit('success');
                } else {
                    this.$emit('fail', res.message);
                }
            });
        }
    }
}
