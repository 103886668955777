










































































































































































































































































































































































































































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Form, Field, Button, RadioGroup, Radio, Picker, Popup, Cell, Uploader } from 'vant';
import { fileData } from '@/core/utils';
import CompressImage from '@/mixins/CompressImage';
import Back from "@/components/Back.vue";

const staticDatas = namespace('staticDatas');

@Component({
    components: {
        'van-form': Form,
        'van-field': Field,
        'van-button': Button,
        'van-radio-group': RadioGroup,
        'van-radio': Radio,
        'van-picker': Picker,
        'van-popup': Popup,
        'van-cell': Cell,
        'van-uploader': Uploader,
      back:Back

    }
})
export default class CreditorRightsDeclare extends Mixins(CompressImage) {
    //证件类型选项
    @staticDatas.State(state => state.select_options.card_type_ids)
    cardTypeOptions!: any;
    showCardTypePicker: boolean = false;

    //担保类型选项
    @staticDatas.State(state => state.select_options.assure_type_ids)
    assureTypeOptions!: any;
    showAssureTypePicker: boolean = false;

    //债权性质选项
    @staticDatas.State(state => state.select_options.creditor_type_ids)
    creditorTypeOptions!: any;
    showCreditorTypePicker: boolean = false;
	
	// 普通债权性质选项
	@staticDatas.State(state => state.select_options.common_creditor)
	generalCreditorTypeOptions!:any;
	showGeneralCreditorTypePicker:boolean=false;
	
    //提交进度
    progress: number = 2;
	
    
    //请求字段
    fields: any = {
        step_1: {
            is_agent: 1,
            //债权人基本信息
            cred_name: '',
            representative: '',
            cred_mobile: '',
            creditor_card: '',
            bank_name: '',
            bank_num: '',
            creditor_bank_ads: '',
            address: '',
            //委托代理人基本信息
            agent_name: '',
            agent_mobile: '',
            card_type: {
                id: undefined,
                name: ''
            },
            agent_card: '',
            agent_unit: ''
        },
        step_2: {
            creditor_type: {
                id: undefined,
                name: ''
            },
			common_creditor:{
				id:undefined,
				name:''
			},
            capital: null,
            interest: null,
			damages:null,
			other_money:null,
            aggregate_amount: null,
            other_info: '',
            is_arbitration: 1,
			arbitration_case_num:null,		//经法院裁决的案件号
			is_execute:1,					//是否申请执行
			execute_case_num:'',			//执行案号和执行情况
            is_guarantee: 1,
            //有担保
            bondsman: '',
            arbitration_type: {
                id: undefined,
                name: ''
            },
            guaranty: '',
            guaranty_owner: '',

            related_condition: '',
			debtor_condition:'',
            creditor_process: ''
        },
        step_3: {
            more_info: '',
            is_agree: true,
            uploadData: {
                card_image: [],
                creditor_evidence: [],
                guarantee_evidence: []
            }
        }
    };

    //禁用字段
    disabledFields: any = {
        cred_name: false,
        representative: false,
        cred_mobile: false,
        creditor_card: false,
        bank_name: false,
        bank_num: false,
        creditor_bank_ads: false,
        address: false
    };
	
	// 监听总金额
	private changeMoney(){
		const moneyArr=[this.fields.step_2.capital,this.fields.step_2.interest,this.fields.step_2.damages,this.fields.step_2.other_money]
		this.fields.step_2.aggregate_amount=moneyArr.reduce((temp,item)=>{
			if(item!==''){
				return temp+item
			}else{
				return temp+0
			}
		})
	}
	
	// 是否显示债权性质子选项
	private show_child_type:boolean=false
	
	// 监听债权性质选择
	private onCreditorTypeChange(value:any){
		if(value.id==0){
			this.show_child_type=true
		}
		this.fields.step_2.creditor_type = value;
		(this.$refs['step_2'] as any).validate('creditor_type');
		this.showCreditorTypePicker = false;
	}
	

	
    //批量上传
    public batchUpload(fileList: any): Promise<any> {
        return new Promise((resolve, reject) => {
            let uploadList = [];
            let urls: any = [];
            for (let k in fileList) {
                const item = fileList[k];
                if (item.file) {
                    uploadList.push(this.$uploadFile.post(this.$URL.UPLOAD_FILE, fileData(item.file)));
                } else {
                    uploadList.push(item.url);
                }
            }
            if (uploadList.length > 0) {
                this.axios.all<any>(uploadList).then(this.axios.spread((...args: any): void => {
                    for (let i = 0; i < args.length; ++i) {
                        const res = args[i];
                        if (typeof res == 'string') {
                            urls.push(res);
                        } else if (res.code == 1) {
                            urls.push(res.data.src);
                        } else {
                            reject();
                            return undefined;
                        }
                    }

                    resolve(urls);
                })).catch(() => {
                    reject();
                });
            } else {
                resolve(urls);
            }
        });
    }

    //上传
    public upload(uploadList: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const list: Promise<any>[] = [];
            let keys: string[] = [];
            for (let k in uploadList) {
                keys.push(k);
                list.push(this.batchUpload(uploadList[k]));
            }
            Promise.all(list).then((values) => {
                let objValue: any = {};
                for (let i in keys) {
                    objValue[keys[i]] = values[i].join(',');
                }
                resolve(objValue);
            }).catch(() => {
                reject();
            });
        });
    }

    //提交数据
    private data: any = {};

    //审核申请人信息
    declareInfo() {
        let step_1_base = (this.$refs.step_1_base as any).validate();
        if (this.fields.step_1.is_agent == 1) {
            //有代理人
            let step_1_agent = (this.$refs.step_1_agent as any).validate();
            Promise.all([step_1_base, step_1_agent]).then(() => {
                ++this.progress;
                Object.assign(this.data, this.fields.step_1);
                this.data['card_type'] = this.fields.step_1.card_type.id;
            });
        } else {
            //无代理人
            step_1_base.then(() => {
                ++this.progress;
                Object.assign(this.data, this.fields.step_1);
				
            });
        }
    }
    
    //审核材料
    declareData() {
        (this.$refs.step_2 as any).validate().then(() => {
            ++this.progress;
            Object.assign(this.data, this.fields.step_2);
            for (let k of ['creditor_type', 'arbitration_type','common_creditor']) {
                this.data[k] = this.fields.step_2[k].id;
            }
        });
    }
    
    //提交申请表
    submitDeclare() {
        (this.$refs.step_3 as any).validate().then(() => {
            Object.assign(this.data, this.fields.step_3);
            for (let k in this.data) {
                const val = this.data[k];
                if (val === '' || val === undefined) {
                    delete this.data[k];
                }
            }
            delete this.data.uploadData;
            this.$toast.loading({
                message: "正在提交",
                forbidClick: true,
                duration: 0
            });
			// console.log(this.fields)
            this.upload(this.fields.step_3.uploadData).then((res) => {
                Object.assign(this.data, res);
                this.data.creditor_id = Number(this.$route.query.id);
				// console.log(this.data)
                this.axios.post(this.$URL.CREDITOR_DECLARE, this.data).then((res: any) => {
					// console.log(this.data)
                    if (res.code == 1) {
                        this.$toast.success("success");
                        this.$router.back();
						localStorage.removeItem('submitInfo')
                    } else {
                        this.$toast.fail(res.message);
                    }
                });
            }).catch(() => {
                this.$toast.fail("Operation failure");
            });
        });
    }
	
	private onDeclareBack(){
		if(this.progress>0){
			this.progress=this.progress-1
		}
	}

    //监听提交审核
    onSubmitDeclare() {
        if (this.progress == 0) {
            this.declareInfo();
        } else if (this.progress == 1) {
			// console.log(this.fields)
            this.declareData();
        } else {
            this.submitDeclare();
        }
    }
	
	// 保存数据
	private onSubmitInfo(){
		this.$store.commit('getSubmitInfo',this.fields)
		this.$toast.success('success')
	}
	
	// 加载已保存数据
	private getSubmitData(){
		if(localStorage.getItem('submitInfo')){
			const info=JSON.parse((localStorage as any).getItem('submitInfo'))
			for(let k in info){
				for(let i in info[k]){
					this.fields[k][i]=info[k][i]
				}
			}
		}else{
			this.$toast('暂无已保存数据')
		}
	}

    //初始字段值
    initFields() {
        this.axios.post(this.$URL.GET_BASE_INFO).then((res: any) => {
			console.log(res)
            if (res.code == 1) {
                const info = res.data.info;
                if (info === null) {
                    for (let k in this.disabledFields) {
                        this.disabledFields[k] = false;
                    }
                } else {
                    this.$nextTick(() => {
                        if (info.identity_type == 1) {
                            //自然人
                            this.fields.step_1.representative = '无';
                            this.disabledFields.representative = true;
                        } else {
                            //管理人
                            this.fields.step_1.representative = info.representative;
                            this.disabledFields.representative = info.representative ? true : false;
                        }
                        this.fields.step_1.creditor_name = info.bank_name || '';
                        this.fields.step_1.creditor_mobile = info.cred_mobile || '';
                        this.fields.step_1.creditor_card = info.contacts_code || '';
                        this.fields.step_1.bank_name = info.bank_name || '';
                        this.fields.step_1.bank_num = info.bank_num || '';
                        this.fields.step_1.creditor_bank_ads = info.opening_bank || '';
                        this.fields.step_1.address = info.company_address || '';
                        for (let k of ['creditor_name', 'creditor_mobile', 'creditor_card', 'bank_name', 'bank_num', 'creditor_bank_ads', 'address']) {
                            this.$set(this.disabledFields, k, this.fields.step_1[k] ? true : false);
                        }
                    });
                }
            }
        });
    }

    created() {
        this.$store.dispatch('staticDatas/init');
        this.initFields();
    }
}
