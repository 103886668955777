




















































import { Vue , Component } from 'vue-property-decorator';
import Back from "@/components/Back.vue";
@Component({
  components:{
    back:Back

  }
})
export default class ElectronicResult extends Vue {
  private is_success:any = undefined ;

  // 获取参数
  private getQueryString(name:any){
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    let r = window.location.search.substr(1).match(reg);
    // console.log( reg ,r )
    if (r != null) {
      return decodeURI(r[2]);
    }
    return ;
  }

  private result:any = {};
  created(){
    let info = this.$route.query ;
    // console.log(info ,  info.result)
    if( info.result == '1'){
      for(let k of ['UserName' , 'MobilePhone' , 'AcNo' , 'returnCode' , 'result']){
        this.result[k] = this.getQueryString( k ) ;
        // this.result[k] = info[k]
      }
      // this.result.UserName = this.getQueryString('UserName') ;
      // this.result.MobilePhone = this.getQueryString('MobilePhone') ;
      // this.result.AcNo = this.getQueryString('AcNo');
      // this.result.returnCode = this.getQueryString('returnCode');
    }else{
      for( let k of ['DpE9999' , 'DpE0000' , 'DpE9507']){
        if(info.returnCode == k){
          info.returnMsg = '信息异常，暂无法开立电子二类户请使用本人名下其他银行卡!'
        }
      }
      for( let k of ['returnMsg' , 'returnCode' , 'result']){
        this.result[k] = this.getQueryString(k)
      }
      // this.result.returnMsg = this.getQueryString('returnMsg') ;
      // this.result.returnCode = this.getQueryString('returnCode') ;
      // this.result.returnCode = this.getQueryString('returnCode') ;
    }
    // console.log(this.result)
  }
}
