//请求地址
export namespace URL {
    export const BASE = '/api';
	// export const BASE = 'https://test.api.polz.cn';
    export const BASE_SRC = BASE;
	export const BASE_URL = BASE;

    export const UPLOAD_FILE = '/parent/Common/upload';
    export const SEND_VERIFY_CODE = '/parent/Common/sms_send';
    export const BANK_SEND_VERIFY_CODE = '/parent/bankcgb/sendVerifyCode';
    export const STATIC_DATAS = '/creditor/Options/options_list';
    export const OPEN_BANK_OPTIONS = '/parent/common/opn_bnk';
	export const AREA_LIST='/parent/common/kd_area_list';                                //城市/区域options


	// 人脸相关
    export const ADD_FACE_LOG='/creditor/creditor/add_face_log';        //获取人脸ID
	export const FACE_RESULT='/parent/nanjingbank/faceResult';          //获取人脸结果
    
    export const LOGIN = '/parent/Login/logon';
    export const REGISTER = '/parent/User/register';
    export const SET_PASSWORD = '/parent/Login/ret_password';
	export const PLATFORM='/parent/login/platform';
	export const MULTI_ACCOUNT_LOGIN='parent/login/multi_account_login'
	export const VERIFY='/parent/login/verify';

    export const HOME_NEWS = '/creditor/News/latest_news';
    export const HOME_NOTICE = '/creditor/Notice/latest_notice';

    export const MESSAGE_UNREAD_NUM = '/creditor/News/news_unread_num';
    export const MESSAGE_LIST = '/creditor/News/news_list';
    export const MESSAGE_DETAIL = '/creditor/News/details';

    export const EXIT_LOGIN = '/parent/Login/logout';
    export const ALTER_AVATAR = '/parent/User/update_profile';
    export const ALTER_NAME = '/parent/User/update_name';
    export const ALTER_PASSWORD = '/parent/User/change_pwd';
    export const ACCOUNT_INFO = '/creditor/Account/account_info';
    export const GET_BASE_INFO = '/creditor/Creditor/creditor_info';
    export const SUBMIT_BASE_INFO = '/creditor/Creditor/creditor_add_info';

    export const CREDITOR_DECLARE = '/creditor/Creditor/creditor_declare';
    export const CREDITOR_DECLARE_LIST = '/creditor/Creditor/creditor_declare_list';
    export const CREDITOR_DECLARE_DETAIL = '/creditor/Creditor/creditor_declare_detail';
    export const CREDITOR_DECLARE_INFO = '/creditor/Creditor/creditor_declare_info';
	export const CREDITOR_DECLARE_DELETE = 'creditor/creditor/del_creditor_declare';

    export const CASE_LIST = '/creditor/Cases/cases_list';
    export const CASE_DETAIL_INFO  = '/creditor/Cases/cases_detail';
    export const CASE_WRIT_INFO = '/creditor/Cases/writer_list';

    export const MEETING_LIST = '/creditor/Meeting/meeting_list';
    export const MEETING_DETAIL = '/creditor/Meeting/meeting_detail';
    export const MEETING_CASE_COMMITTEE_INFO = 'creditor/Meeting/case_committee_list';
    export const MEETING_JOIN = '/creditor/Meeting/join_meeting';
    export const MEETING_INFO = '/creditor/Meeting/meeting_info';
    export const MEETING_SIGN_IN = '/creditor/Meeting/sign_in_meeting';
    export const MEETING_VOTE = '/creditor/Meeting/add_matters_result';
	export const MEETING_VOTE_CHONGQING='/creditor/Meeting/add_matters_result_chongqing';
    export const MEETING_ISSUES_LIST = '/creditor/Meeting/get_meeting_message';
    export const MEETING_SUBMIT_ISSUES = '/creditor/Meeting/add_meeting_message';
	export const GET_MATTERS_INFO='/creditor/Meeting/get_matters_info';
	export const ADD_INSPECT_RESULT='/creditor/inspect/add_inspect_result';


    export const NOTICE_LIST = '/creditor/Notice/notice_list';
    export const NOTICE_DETAILS = '/creditor/Notice/notice_detail';

    export const ADD_CREDITOR_USER_INFO = '/parent/user/add_creditor_user_info';
    export const CREDITOR_RIGHT_REGISTER_TITLE = '/parent/Creditor/cases_info';
	
	// 资金管理
	export const BANK_ACCOUNT_LIST = '/creditor/Bankaccount/bank_account_list';
	export const NATION_LIST='/creditor/bankaccount/nation_list';
	export const CARD_OCR='/parent/Common/card_ocr';
	export const DETECT_AUTH='/parent/Common/detect_auth';
	export const FUNDS_ADD_BANK='/creditor/Bankaccount/add_bank';
	export const FUNDS_CASH_OUT='/creditor/Bankaccount/accountWithdraw';
	export const FUNDS_ACCOUNT_QUERY='/creditor/Bankaccount/accountQuery';
	export const FUNDS_FLOW_DETAIL='/creditor/Bankaccount/transactionQueryAPI';
	export const FUNDS_JOB_LIST='/creditor/Bankaccount/job_list';
	export const MINI_ACCOUNT_LIST='/creditor/Bankaccount/mini_bank_account_list';
	export const NATION_CODE='/creditor/Bankaccount/nation_code';
	export const BANK_NAME='/creditor/Bankaccount/bank_name';
	export const IDCARD_OCR='/parent/nanjingbank/idCardOCR';            //南京银行提供--身份证OCR识别
	export const BANK_CARD_OCR='/parent/nanjingbank/bankCdIdentify';    //南京银行提供--银行卡OCR识别

	export const CREDITOR_FILE_LIST='/creditor/meeting/creditor_file_list';                         //债权人需签署文件列表
	export const EDIT_CREDIOT_FILE='/creditor/meeting/edit_creditor_file';                         //债权人签署文件
	export const CREDITOR_PERSONAL_INFO_REPORT='/creditor/account/creditor_personal_info_report';    //个人信息申报
	export const INTEL_AREA_CODE_LIST='/creditor/account/intel_area_code_list';                     //获取国际区号
	export const CREDITOR_PERSONAL_INFO_DETAIL='/creditor/account/creditor_personal_info_detail';     //获取个人信息详情

	export const CREDITOR_FILE_REPORT='/creditor/meeting/creditor_file_report';                       //债权人文件信息上传
	export const CREDITOR_FILES_LIST='/creditor/meeting/creditor_files_list';                           //债权人文件信息列表

}

//全局声明
interface URL {
    //基地址
    readonly BASE_URL: string;

    //杂项
    readonly UPLOAD_FILE: string;                   //上传文件
    readonly SEND_VERIFY_CODE: string;              //发送验证码
    readonly BANK_SEND_VERIFY_CODE: string;         //银行发送验证码
    readonly STATIC_DATAS: string;                  //选择选项与模板文件
    readonly OPEN_BANK_OPTIONS: string;             //开户行选项
	readonly AREA_LIST:string;                      //获取地址选项


	//账号
    readonly LOGIN: string;                         //账号登录
    readonly REGISTER: string;                      //账号注册
    readonly SET_PASSWORD: string;                  //设置密码
	readonly PLATFORM:string;						//获取平台ID
    readonly ADD_FACE_LOG:string;                   //获取人脸ID
	readonly FACE_RESULT:string;                    //获取人脸结果
	readonly MULTI_ACCOUNT_LOGIN:string;            //多端登录
	readonly VERIFY:string;                         //获取图片二维码
    

    //首页
    readonly HOME_NEWS: string;                      //首页资讯栏
    readonly HOME_NOTICE: string;                    //首页公告栏

    //消息
    readonly MESSAGE_UNREAD_NUM: string;            //未读消息数量
    readonly MESSAGE_LIST: string;                  //消息列表
    readonly MESSAGE_DETAIL: string;                //消息详情

    //账号
    readonly EXIT_LOGIN: string;                    //退出登录
    readonly ALTER_AVATAR: string;                  //修改头像
    readonly ALTER_NAME: string;                    //修改姓名
    readonly ALTER_PASSWORD: string;                //修改密码
    readonly ACCOUNT_INFO: string;                  //账号信息
    readonly GET_BASE_INFO: string;                 //获取基本信息
    readonly SUBMIT_BASE_INFO: string;              //提交基本信息

    //债权申报
    readonly CREDITOR_DECLARE: string;              //债权申报
    readonly CREDITOR_DECLARE_LIST: string;         //债权申报列表
    readonly CREDITOR_DECLARE_DETAIL: string;       //债权申报详情
    readonly CREDITOR_DECLARE_INFO: string;         //债权申报信息
	readonly CREDITOR_DECLARE_DELETE:string;		//删除债权申报

    //案件管理
    readonly CASE_LIST: string;                     //案件列表
    readonly CASE_DETAIL_INFO: string;              //案件详情信息
    readonly CASE_WRIT_INFO: string;                //案件文书信息

    //债权人会议
    readonly MEETING_LIST: string;                  //会议列表
    readonly MEETING_DETAIL: string;                //会议详情
    readonly MEETING_CASE_COMMITTEE_INFO: string;   //会议委员会信息
    readonly MEETING_JOIN: string;                  //加入会议
    readonly MEETING_INFO: string;                  //会议信息
    readonly MEETING_SIGN_IN: string;               //会议签到
    readonly MEETING_VOTE: string;                  //会议表决
	readonly MEETING_VOTE_CHONGQING:string;         //会议表决-重庆某特殊案件
    readonly MEETING_ISSUES_LIST: string;           //留言问题列表
    readonly MEETING_SUBMIT_ISSUES: string;         //提交留言问题
	readonly GET_MATTERS_INFO:string;               //获取表决详情
	readonly ADD_INSPECT_RESULT:string;             //提交债权核查

    //资金管理
    readonly BANK_ACCOUNT_LIST: string;             //银行账户列表

    //公告管理
    readonly NOTICE_LIST: string;                   //公告列表
    readonly NOTICE_DETAILS: string;                //公告详情

    //债权登记
    readonly ADD_CREDITOR_USER_INFO: string;        //提交登记表单
    readonly CREDITOR_RIGHT_REGISTER_TITLE: string; //债权登记标题
	
	readonly NATION_LIST:string;					//民族选项
	readonly CARD_OCR:string;						//OCR识别
	readonly DETECT_AUTH:string;					//获取人脸验证
	readonly FUNDS_ADD_BANK:string;					//开设电子账户
	readonly FUNDS_CASH_OUT:string;					//提现
	readonly FUNDS_ACCOUNT_QUERY:string;			//查询余额
	readonly FUNDS_FLOW_DETAIL:string;				//流水详情
	readonly FUNDS_JOB_LIST:string;					//职业选项
	readonly MINI_ACCOUNT_LIST:string;				//银行电子账户列表
	readonly NATION_CODE:string;					//获取民族代码
	readonly BANK_NAME:string ;						//获取银行名称
	readonly IDCARD_OCR:string;                     //OCR识别身份证（南京银行提供）
	readonly BANK_CARD_OCR:string;                  //OCR识别银行卡（南京银行提供）


	//债权人签署文件
	readonly CREDITOR_FILE_LIST:string;             //债权人需签署文件列表
	readonly EDIT_CREDIOT_FILE:string;              //债权签署文件
	readonly CREDITOR_PERSONAL_INFO_REPORT:string;   //个人信息申报
	readonly INTEL_AREA_CODE_LIST:string;           //获取国际区号
	readonly CREDITOR_PERSONAL_INFO_DETAIL:string;   //获取个人信息详情

	readonly CREDITOR_FILE_REPORT:string;                  //债权人文件信息上传
	readonly CREDITOR_FILES_LIST:string;                   //债权人文件信息列表
}

declare module "vue/types/vue" {
    interface Vue {
        $URL: URL
    }
}