/*
    下载文件
*/
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export default class Download {
    private axios: AxiosInstance;

    constructor(config?: AxiosRequestConfig) {
        this.axios = axios.create({
            ...config,
            responseType: 'blob'
        });
    }

    //获取文件
    public get(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return this.axios.get(url, config);
    }

    //导出文件
    public export(data: any, name: string = 'download') {
        //下载
        const download = function(url: string) {
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };
        if (data instanceof Blob) {
            const URL = (window.URL || window.webkitURL || window);
            let url = URL.createObjectURL(data);
            download(url);
            URL.revokeObjectURL(url);
        } else {
            download(data);
        }
    }

    //保存文件
    public save(url: string, name?: string, config?: AxiosRequestConfig) {
        this.get(url, config).then((res: any) => {
            this.export(res.data, name);
        });
    }
}