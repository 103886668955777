





























































import { Vue, Component } from 'vue-property-decorator';
import { Form, Field, Button } from 'vant';
import Back from "@/components/Back.vue";

@Component({
    components: {
        'van-form': Form,
        'van-field': Field,
        'van-button': Button,
      back:Back

    }
})
export default class AlterPassword extends Vue {
    //请求字段
    fields: any = {
        id: null,
        old_pwd: '',
        password: '',
        new_pwd: '',
    };

    //Change password
    alterPassword(fields: any) {
        this.$toast.loading({
            message: "正在修改",
            forbidClick: true,
            duration: 0
        });
        this.axios.post(this.$URL.ALTER_PASSWORD, fields).then((res: any) => {
            if (res.code == 1) {
                this.$toast.success("success");
                this.$router.back();
            } else {
                this.$toast.fail(res.message);
            }
        });
    }

    //监听Change password
    onAlterPassword() {
        this.alterPassword(this.fields);
    }

    created() {
        this.fields.id = this.$store.state.account.id;
    }
}
