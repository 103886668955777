var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page register"},[_c('back',{on:{"routerBack":function($event){return _vm.$router.replace('/login')}}}),_c('van-form',{staticClass:"register_form",on:{"submit":_vm.onSetPassword}},[_c('figure',{staticClass:"account_form-logo"},[_c('img',{attrs:{"src":_vm.system_info.logo}}),_c('figcaption',[_vm._v(_vm._s(_vm.system_info.name))])]),_c('van-field',{attrs:{"type":"text","name":"mobile","placeholder":"Please enter your phone number","label":"Mobile number","border":false,"maxlength":"11","rules":[
                    { required: true, message: 'Please enter your mobile number' },
                    { pattern: /^\d{11}$/, message: '请输入正确的手机号码' }
                ]},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('b',{staticClass:"icon icon-btn_close",attrs:{"data-field":"mobile"},on:{"click":_vm.onEmptyField}})]},proxy:true}]),model:{value:(_vm.fields.mobile),callback:function ($$v) {_vm.$set(_vm.fields, "mobile", $$v)},expression:"fields.mobile"}}),_c('van-field',{attrs:{"type":"text","name":"sms_code","placeholder":"请输入验证码","label":"Verification Code","border":false,"maxlength":"8","format-trigger":"onBlur","rules":[{ required: true, message: '请输入验证码' }]},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('send-verify-code',{staticClass:"btn-sms-code",attrs:{"mobile":_vm.fields.mobile,"event":"ret_pwd"},on:{"fail":function($event){return _vm.$toast.fail($event)},"success":function($event){return _vm.$toast.success('Sent successfully')}}},[_vm._v(" 获取验证码 ")])]},proxy:true}]),model:{value:(_vm.fields.sms_code),callback:function ($$v) {_vm.$set(_vm.fields, "sms_code", $$v)},expression:"fields.sms_code"}}),_c('van-field',{staticClass:"psw_field",attrs:{"type":"password","name":"password","placeholder":"Please enter your new password","label":"New password","border":false,"format-trigger":"onBlur","autocomplete":"off","rules":[
                    { required: true, message: 'Please enter your new password' },
                    { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d$@$!%*?&]{6,15}$/, message: 'Passwords are 6-15 characters long, with at least one number and one letter' }
                ]},model:{value:(_vm.fields.password),callback:function ($$v) {_vm.$set(_vm.fields, "password", $$v)},expression:"fields.password"}}),_c('van-field',{staticClass:"psw_p_field",attrs:{"type":"password","placeholder":"请再次输入密码","label":"再次输入密码","border":false,"format-trigger":"onBlur","autocomplete":"off","rules":[
                    { required: true, message: '请再次输入密码' },
                    {
                        validator: function (value, rule) { return _vm.fields.password == _vm.again_password },
                        message: '两次输入密码不一致，请重新输入！' 
                    }
                ]},model:{value:(_vm.again_password),callback:function ($$v) {_vm.again_password=$$v},expression:"again_password"}}),_c('p',{staticClass:"account_form-text_hint"},[_vm._v("提示：Passwords are 6-15 characters long, with at least one number and one letter")]),_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit"}},[_vm._v("Submit")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }