










































































































































































































































































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Form, Field, Button, RadioGroup, Radio, Picker, Popup, Cell, Uploader } from 'vant';
import SendVerifyCode from '@/components/SendVerifyCode.vue';
import { fileData } from '@/core/utils';
import CompressImage from '@/mixins/CompressImage';
import Back from "@/components/Back.vue";

const staticDatas = namespace('staticDatas');

@Component({
    components: {
        'van-form': Form,
        'van-field': Field,
        'van-button': Button,
        'van-radio-group': RadioGroup,
        'van-radio': Radio,
        'van-picker': Picker,
        'van-popup': Popup,
        'van-cell': Cell,
        'van-uploader': Uploader,
        'send-verify-code': SendVerifyCode,
      back:Back

    }
})
export default class CreditorRightsDeclare extends Mixins(CompressImage) {

    //证件类型选项
    @staticDatas.State(state => state.select_options.card_type_ids)
    cardTypeOptions!: any;
    showCardTypePicker: boolean = false;

    //开户行选项
    openingBankOptions: any = [];
    showOpenBankPicker: boolean = false;

    //账户类型选项
    @staticDatas.State(state => state.select_options.account_type_ids)
    accountTypeOptions!: any;
    showAccountTypePicker: boolean = false;

    //请求字段
    fields: any = {
        identity_type: 1,
        person: {
            cred_name: '',
            address: '',
            card_type: {
                id: undefined,
                name: ''
            },
            card_num: '',
            identity: {
                identity_font: [],
                identity_behind: [],
                identity_hand: []
            },
            //债权人银行账户
            bank_num: '',
            opening_bank: {
                id: undefined,
                open_bank_no: undefined,
                open_bank_name: ''
            },
            contacts_mobile: '',
            ver_code: ''
        },
        company: {
            company_name: '',
            unify_code: '',
            representative: '',
            card_type: {
                id: undefined,
                name: ''
            },
            card_num: '',
            company_address: '',
            mobile: '',
            ver_code: '',
            identity: {
                identity_font: [],
                identity_behind: [],
                identity_hand: [],
                business_license: []
            },
            //企业银行账户
            company_account: '',
            account_number: '',
            bank_accno_type: {
                id: undefined,
                name: ''
            },
            opening_bank: {
                id: undefined,
                open_bank_no: undefined,
                open_bank_name: ''
            },
            contacts_mobile: ''
        }
    };

    //获取开户行选项
    getOpenBankOptions() {
        this.axios.post(this.$URL.OPEN_BANK_OPTIONS).then((res: any) => {
            if (res.code == 1) {
                this.openingBankOptions = res.data.list;
            }
        });
    }

    //上传身份证
    private uploadIdentityCard(identity: any, callback: Function) {
        let updateList = [];
        let urls: any = {};
        let names: string[] = [];
        for (let k in identity) {
            const item = identity[k][0];
            names.push(k);
            updateList.push(this.$uploadFile.post(this.$URL.UPLOAD_FILE, fileData(item.file)));
        }
        this.axios.all(updateList).then(this.axios.spread((...args: any): void => {
            for (let i = 0; i < args.length; ++i) {
                const res = args[i];
                if (res.code == 1) {
                    urls[names[i]] = res.data.src;
                } else {
                    this.$toast.fail("Operation failure");
                    return undefined;
                }
            }
            callback(urls);
        }));
    }

    //提交认证
    submitAuthen(fields: any) {
        this.axios.post(this.$URL.SUBMIT_BASE_INFO, fields).then((res: any) => {
            if (res.code == 1) {
                this.$store.commit('account/setAuthen', true);
                this.$toast.success("success");
                this.$router.replace('/');
            } else {
                this.$toast.fail(res.message);
            }
        });
    }

    //监听提交自然人认证
    onSubmitPerson(values: any) {
        delete values['undefined'];

        this.$toast.loading({
            message: "正在提交",
            forbidClick: true,
            duration: 0
        });

        this.uploadIdentityCard(this.fields.person.identity, (res: any) => {
            this.submitAuthen({ ...values, ...res, identity_type: this.fields.identity_type });
        });
    }

    //监听提交企业认证
    onSubmitCompany(values: any) {
        delete values['undefined'];
        
        this.$toast.loading({
            message: "正在提交",
            forbidClick: true,
            duration: 0
        });

        this.uploadIdentityCard(this.fields.company.identity, (res: any) => {
            this.submitAuthen({ ...values, ...res, identity_type: this.fields.identity_type });
        });
    }
    
    created() {
        this.$store.dispatch('staticDatas/init');
        if (this.$store.state.account.bank_flag == 1) {
            this.getOpenBankOptions();
        }
    }
}
