






































































































































import { Vue, Component } from 'vue-property-decorator';
import { Step, Steps } from 'vant';
import { namespace } from 'vuex-class';
import Back from "@/components/Back.vue";

const staticDatas = namespace('staticDatas');

@Component({
  components: {
    'van-step': Step,
    'van-steps': Steps,
    back:Back
  }
})
export default class CaseDetails extends Vue {

  // 获取Case progress
  @staticDatas.State(state => state.select_options.case_progress_ids)
  case_progress!: any;

  //详情
  detail: any = {};

  //获取详情
  getDetail(id: number) {
    this.axios.post(this.$URL.CASE_DETAIL_INFO, { id }).then((res: any) => {
      console.log(res)
      if (res.code == 1) {
        this.detail = res.data.detail;
      } else {
        this.$notify(res.message);
      }
    });
  }

  created() {
    this.$store.dispatch('staticDatas/init');
    this.getDetail(Number(this.$route.query.id));
  }
}
