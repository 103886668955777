


































































































































import { Vue, Component } from 'vue-property-decorator';
import Back from "@/components/Back.vue";

@Component({
  components:{
    back:Back
  }
})
export default class PrivacyPolicy extends Vue {
}
