/*
    数据仓库
*/
import Vue from 'vue';
import Vuex from 'vuex';
import account from './account';
import staticDatas from './staticDatas';
import submitData from './sunbmitData';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
    },
    mutations: {
    },
    modules: {
        account,
        staticDatas,
		submitData,

    }
});

export default store;