<template>
  <div class="signatureBox">
    <!--    <div class="signature-title">Sign</div>-->

    <div ref="canvasHW" class="canvasBox">
      <canvas
          ref="canvasF"
          @touchstart="touchStart"
          @touchmove="touchMove"
          @touchend="touchEnd"
          @mousedown="mouseDown"
          @mousemove="mouseMove"
          @mouseup="mouseUp"
      ></canvas>
    </div>
    <div class="signature-btn">
      <van-button class="btn back" type="info" @click.native.prevent="handleGoBack()">Back</van-button>
      <van-button class="btn refresh" type="info" @click.native.prevent="handleOverwrite()">Refresh</van-button>
      <van-button class="btn confirm" type="info" @click.native.prevent="handleSubmit()">Confirm</van-button>
    </div>
  </div>
</template>
<script>
import {Button } from 'vant';

export default {
  name: "Signature",
  components:{
    'van-button':Button
  },
  data() {
    return {
      points: [],
      canvasTxt: null,
      stage_info: [],
      startX: 0,
      startY: 0,
      moveY: 0,
      moveX: 0,
      isDown: false,
      strokeStyle: "#000",
      lineWidth: 2
    };
  },
  mounted() {
    this.initCanvas();
  },
  methods: {
    initCanvas() {
      let canvas = this.$refs.canvasF;
      // 获取画布的高度
      canvas.height = this.$refs.canvasHW.offsetHeight - 20;
      // 获取画布的宽度
      canvas.width = this.$refs.canvasHW.offsetWidth - 20;
      // 创建 context 对象
      this.canvasTxt = canvas.getContext("2d");
      this.stage_info = canvas.getBoundingClientRect();
    },
    // 电脑设备事件
    mouseDown(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (ev) {
        let obj = {
          x: ev.offsetX,
          y: ev.offsetY
        };
        this.startX = obj.x;
        this.startY = obj.y;
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.points.push(obj);
        this.isDown = true;
      }
    },
    // 移动设备事件
    touchStart(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (ev.touches.length == 1) {
        let obj = {
          x: ev.targetTouches[0].clientX - this.stage_info.left,
          y: ev.targetTouches[0].clientY - this.stage_info.top
        };
        this.startX = obj.x;
        this.startY = obj.y;
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.points.push(obj);
      }
    },
    // 电脑设备事件
    mouseMove(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (this.isDown) {
        let obj = {
          x: ev.offsetX,
          y: ev.offsetY
        };
        this.moveY = obj.y;
        this.moveX = obj.x;
        this.canvasTxt.strokeStyle = this.strokeStyle;
        this.canvasTxt.lineWidth = this.lineWidth;
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.startY = obj.y;
        this.startX = obj.x;
        this.points.push(obj);
      }
    },
    // 移动设备事件
    touchMove(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (ev.touches.length == 1) {
        let obj = {
          x: ev.targetTouches[0].clientX - this.stage_info.left,
          y: ev.targetTouches[0].clientY - this.stage_info.top
        };
        this.moveY = obj.y;
        this.moveX = obj.x;
        // 设置线条颜色
        this.canvasTxt.strokeStyle = this.strokeStyle;
        // 设置线条宽度
        this.canvasTxt.lineWidth = this.lineWidth;
        // 绘制开始路径
        this.canvasTxt.beginPath();
        // 定义线条开始坐标
        this.canvasTxt.moveTo(this.startX, this.startY);
        // 定义线条结束坐标
        this.canvasTxt.lineTo(obj.x, obj.y);
        // 绘制线条
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.startY = obj.y;
        this.startX = obj.x;
        this.points.push(obj);
      }
    },
    // 电脑设备事件
    mouseUp(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (ev) {
        let obj = {
          x: ev.offsetX,
          y: ev.offsetY
        };
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.points.push(obj);
        this.points.push({ x: -1, y: -1 });
        this.isDown = false;
      }
    },
    // 移动设备事件
    touchEnd(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (ev.touches.length == 1) {
        let obj = {
          x: ev.targetTouches[0].clientX - this.stage_info.left,
          y: ev.targetTouches[0].clientY - this.stage_info.top
        };
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.points.push(obj);
        this.points.push({ x: -1, y: -1 });
      }
    },
    // 返回
    handleGoBack() {
      this.handleOverwrite();
      this.$emit("back");
    },
    // 重写
    handleOverwrite() {
      this.canvasTxt.clearRect(
          0,
          0,
          this.$refs.canvasF.width,
          this.$refs.canvasF.height
      );
      this.points = [];
    },
    // 提交
    handleSubmit() {
      // if (this.points.length < 50) {
      //   return;
      // }
      let img = this.$refs.canvasF.toDataURL('image/png')
      console.log( img , '123123' )
      this.$emit("confirm", img);
    }
  }
};
</script>

<style scoped>
.signatureBox {
  /*position: fixed;*/
  /*bottom: 0;*/
  /*left: 0;*/
  /*right: 0;*/
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;

}
.signature-title {
  width: 100vh;
  height: 42px;
  text-align: center;
  font-size: 24px;
  padding: 10px 0;
  background-color: #2263E8;
  color: #fff;
  transform: rotate(90deg);
  position: absolute;
  right: 0;
  top: 0;
}
.canvasBox {
  /*padding: 10px;*/
  width: 100%;
  height: 500px;
}
.canvasBox canvas {
  width: 100%;
  height: 100%;
  border: 1px solid #2263E8;
  box-sizing: border-box;
}
.signature-btn {
  margin-top: 50px;
  /*height: 42px;*/
  width: 100%;
  /*height: 100%;*/
  padding: 10px ;
  display: flex;
  justify-content: space-around;
  /*flex-direction: column-reverse;*/
  align-items: center;
  box-sizing: border-box;
  position: relative;
}

.btn {
  height: 52px;
  width: 160px;
  background-color: #2263E8;
  color: white;
  font-size: 24px;
  text-align: center;
  line-height: 42px;
  /*display: inline-block;*/
  border-radius: 8px;
  margin: 0 25px;
  cursor: pointer;

  /*position: absolute;*/
  /*bottom: 120px;*/
  /*left: 24px;*/
  /*transform-origin: 0 0;*/
  /*transform: rotate(90deg);*/
}
/*.btn.back {*/
/*  bottom: 400px;*/

/*}*/
/*.refresh.btn{*/
/*  bottom: 260px;*/
/*}*/
/*.btn.confirm {*/
/*  bottom: 120px;*/
/*}*/
</style>