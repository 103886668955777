






































import { Vue, Component } from 'vue-property-decorator';
import { Tab, Tabs, List, Cell ,Loading , Overlay  } from 'vant';
import Back from "@/components/Back.vue";

@Component({
    components: {
        'van-tab': Tab,
        'van-tabs': Tabs,
        'van-list': List,
        'van-cell': Cell,
        'van-loading':Loading,
        'van-overlay':Overlay,
      back:Back
    }
})
export default class Meeting extends Vue {
    //标签索引
    tabActive: number = 0;
    //列表数据
    list: any = [];
    //当前页码
    page: number = 1;
    //加载状态
    loading: boolean = false;
    //完成状态
    finished: boolean = false;
    //遮罩层
    showOverlay:boolean = false ;
    
    //获取会议列表
    getMeetingList( page: number ) {
        // this.loading = true;
      this.showOverlay = true ;
      this.axios.post(this.$URL.MEETING_DETAIL, { page, case_id: 'mobile' }).then((res: any) => {
        // console.log(res , this.$store.state.staticDatas.firstLoadMeetingList ,  '会议列表')
        let goMeetingRoom = this.$store.state.staticDatas.firstLoadMeetingList;
        if (res.code == 1) {
          const list = res.data.list;
          if (list.length == 1 ){
            if(goMeetingRoom){    //判断是否直接进入会议详情页面
              this.$store.commit('staticDatas/first_login' , false)
              this.$router.push({
                path:`/meeting/room?id=${list[0].meeting_id}`
              })
            }else{
              this.page = page;
              for (let item of list) {
                this.list.push(item);
              }
            }
          } else if( list.length > 1) {
            this.page = page;
            for (let item of list) {
              this.list.push(item);
            }
          } else {
            this.finished = true;
          }
          this.$nextTick(()=>{
            this.showOverlay = false
          })
        }
      }).finally(() => {
        this.loading = false;
        // this.showOverlay = false ;
      });
    }

    // //标签改变
    // onTabChange(index: any) {
    //     this.page = 1;
    //     this.list = [];
    //     this.loading = false;
    //     this.finished = false;
    //     this.getMeetingList(index, this.page);
    // }

    //列表加载
    onLoad() {
        this.getMeetingList( this.page + 1);
    }


    created() {
        this.getMeetingList( this.page);
    }
}
