








































































































import { Vue , Component } from 'vue-property-decorator';
import { Form, Field, Button, Cell , Loading } from 'vant';
import SendVerifyCode from '@/components/SendVerifyCode.vue';
import Back from "@/components/Back.vue";
@Component({
	components:{
        'van-form' : Form ,
        'van-field' : Field ,
        'van-button' : Button ,
        'van-cell' : Cell ,
		'van-loading' : Loading ,
		'send-verify-code': SendVerifyCode,
    back:Back
  }
})
export default class CashOut extends Vue {
	// 提交参数
	private params:any={
		IdNo: '',
		Amount:'',
		name: '',
		MobilePhone:'',
		password:'',
		sms_code:'',
	}
	
	private all_cash_loading:boolean=false;
	// 全部提现
	// private AllCashOut(){
	// 	this.params.Amount = this.balance
	// }
	
	// 全部提现
	private AllCashOut(){
		this.all_cash_loading = true;
		this.axios.post(this.$URL.FUNDS_ACCOUNT_QUERY ).then((res:any)=>{
			console.log(res)
			if(res.code == 1){
				if(res.data.returnCode == '000000'){
					this.params.Amount = res.data.data.Balance;
					this.all_cash_loading = false ;
				}else{
					this.$toast.fail(res.data.returnMsg);
					this.all_cash_loading = false ;
				}
			}else{
				this.$toast.fail('余额查询失败');
				this.all_cash_loading = false ;
			}
		}).finally(() => {
            this.all_cash_loading = false;
        });
	}
	
	private onResult(){
		let form = (this.$refs.Cash_form as any)
		form.validate().then(()=>{
            this.$toast.loading({
                message: "正在投票",
                forbidClick: true,
                duration: 0
            });
			let data:any={};
			Object.assign(data,this.params)
			// console.log(data)
			this.axios.post(this.$URL.FUNDS_CASH_OUT , data ).then((res:any)=>{
				console.log(res)
				if(res.code == 1){
					if(res.data.returnCode == '000000'){
						this.$toast.success('提现成功');
						this.$router.replace('/funds');
					}else{
						this.$toast.fail(res.data.returnMsg)
					}
				}else{
					this.$toast.fail(res.message)
				}
			})
		})
	}
}
