

































import {Component, Prop, Vue } from "vue-property-decorator";
import {RadioGroup, Radio, Dialog} from 'vant';

@Component({
  components:{
    'van-radio-group': RadioGroup,
    'van-radio': Radio,
  }
})
export default class DeclareConfirm extends Vue {

  // private loading:boolean = false


  @Prop({
    type:Array,
    default:()=>[]
  })
  private inspect!:any;

  private get isAllowSubmit(){
    if( this.inspect.every((item:any)=>{ return item.result_time != 0 })){
      return true
    }else{
      return false
    }
  }



  private result:any=[ ];
  //提交
  private submit(){
    //判断核查时间
    // if()
    const now:any = new Date().getTime();
    if( now < (this.inspect[0].start_time *1000 )){
      this.$toast.fail('未到核查开始时间');
      return;
    }else if( now > (this.inspect[0].end_time *1000 ) ){
      this.$toast.fail('核查已结束');
      return;
    }
    //
    if( this.inspect.some((item:any)=>{ return !item.result }) ){
      Dialog.confirm({
        title: '提示',
        message: '你有表决项未选择',
      }).then(()=>{Dialog.close()}).catch(()=>{Dialog.close()})
      return
    }

    // 提交
    Dialog.confirm({
      title: '提示',
      message: '提交后不可更改，确认是否提交',
    }).then(() => {
      this.$toast.loading({
        message: "正在投票",
        forbidClick: true,
        duration: 0
      });
      let result:any = this.inspect.map((item:any)=>{ return  { id:item.id , result: item.result , result_hint:item.result_hint } })
      this.axios.post(this.$URL.ADD_INSPECT_RESULT , { meeting_id : this.$route.query.id , inspect_array:JSON.stringify(result)}).then((res:any)=>{
        console.log( res , '提交结果')
        if(res.code == 1){
          this.$emit('success')
        }else{
          this.$toast.fail(res.message);
        }
      })
    })

  }

}
