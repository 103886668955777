import axios, { AxiosStatic } from 'axios';
import { URL } from '@/axios/URL';

//上传文件
export const noSign = axios.create({
    baseURL: URL.BASE_URL,
});


declare module "vue/types/vue" {
    interface Vue {
        $noSign: AxiosStatic
    }
}