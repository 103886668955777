

































































	import { Vue, Component } from 'vue-property-decorator';
  import Back from "@/components/Back.vue";
	@Component({
    components:{
      back:Back

    }
  })
	export default class CreditorDeclareResult extends Vue{
		
		private detail:any={
			capital_2:'',
			interest_2:'',
			damages_2:'',
			other_amount_2:'',
			aggregate_amount_2:'',
			undetermined_amount_2:'',
			pass_amount_2:'',
			remark_2:'',
		}
		
		private getDetail(id:string){
			this.axios.post(this.$URL.CREDITOR_DECLARE_DETAIL, { case_id:id, limit:999 }).then((res: any) => {
				if (res.code == 1) {
					console.log(res)
					const list=res.data.list
					for(let k in list){
						if(list[k].creditor_id==this.$route.query.creditor_id){
							for(let i in this.detail){
								this.detail[i]=list[k][i]
							}
						}
					}
				}else{
					this.$toast.fail(res.message)
				}
			}).finally(() => {
				// this.loading = false;
			});
		}
		created(){
			this.getDetail(String(this.$route.query.c_id))
		}
		
	}
	
