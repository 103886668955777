









































































import { Component, Mixins } from 'vue-property-decorator';
import EmptyField from '@/mixins/EmptyField';
import { Form, Field, Button , Tab , Tabs } from 'vant';
import SendVerifyCode from '@/components/SendVerifyCode.vue';
import wx from 'weixin-js-sdk';
import User from '@/core/User.ts';
// import { face_info } from '@/core/utils.ts';

@Component({
    components: {
        'van-form': Form,
        'van-field': Field,
        'van-button': Button,
        'send-verify-code': SendVerifyCode,
        'van-tab': Tab,
        'van-tabs': Tabs,
    }
})
export default class Login extends Mixins(EmptyField) {
    //提交字段
    fields: any = {
        mobile : '' ,
        password : '' ,
        plat_id : '' ,
    };

    // 验证码登录字段
    params:any = {
      mobile : '',
      sms_code : '',
      plat_id : '',
      type : 1,
    }

    // 标签索引
    private tabActive : number = 0;

    private onTabChange(){
      // console.log(this.tabActive)
    }

    //登录图片验证码
    private code_img:any='';

    //提交登录
    login() {
        // this.$toast.loading({
        //     message: "正在登录",
        //     forbidClick: true,
        //     duration: 0
        // });
        let data:any = {};
        if( this.tabActive == 0 ){
          for(let k in this.fields){
            data[k] = this.fields[k]
          }
        }else{
          for(let k in this.params){
            data[k] = this.params[k]
          }
        }
        data.login_type = 1;        //  用来判断是PC登录还是H5登录，0 PC；1 H5

        this.axios.post(this.$URL.LOGIN, data).then((res: any) => {
          console.log('登录结果' , res)
            if (res.code == 1) {
              const data = res.data;
              let account:any ;
              // let credit:any ;
              //多端账户获取债权人端账号信息
              if( data.length > 0){
                for(let k in data){
                  if(data[k].type == 2){
                    account = data[k].account
                    // credit = data[k].credit
                  }
                }
              }else {
                account = data.account;
                // credit = data.credit;
              }

              if ( account.type == 2 ) { // type = 2 ，债权人账号
                //储存登录信息
                this.$store.commit('account/setInfo', account);
                this.$store.commit('staticDatas/first_login' , true );
                // const id_info:any = face_info.get( account.id );
                // console.log( id_info , 'id_info' );

                if( account.is_first_login === 1){
                  this.$router.replace('/collect_creditor_info');
                }else{
                  this.$router.replace('/' );
                }
              } else if ( account.type == 0 ) { // 管理人账号，直接跳转扫码页面
                wx.miniProgram.navigateTo({ url: '/pages/scanLogin/scanLogin?token=' + account.token })
                this.$toast.clear();
              } else {
                this.$toast.fail("登录失败！该用户暂未添加至债权人白名单");
              }
            } else {
                this.$toast.fail(res.message);
            }
        });
    }
    //监听登录
    onLogin() {
      // console.log( this.tabActive )
      // let form = ( this.$refs.login_form as any)
      // form.validate([]).then(()=>{
      // 	this.login();
      // })
      if(this.tabActive == 0 ){
        if( this.fields.mobile == ''|| this.fields.password == '' ){
          this.$toast('Please enter your phone number and password')
        }else{
          this.login();
        }
      }else{
        if( this.params.mobile == ''|| this.params.sms_code == '' ){
          this.$toast('Please enter your phone number and verification code')
        }else{
          this.login();
        }
      }
    }


    private system_info:any={};
    //南京银行开户传入的案件ID
    private case_num?:any;
    //支付宝小程序进入所带参数
    private system?:any;
    created(){

      //获取案号
      this.case_num = this.$route.query.case_num;
      this.system = this.$route.query.system;
      let domain = window.location.host;
      if(domain.indexOf('localhost')> -1){
        domain = 'm.polz.hk'
      }
      // domain = 'm.polz.cn'

      // console.log(domain)
      //获取平台信息
      this.axios.post(this.$URL.PLATFORM , { domain : domain }).then((res:any)=>{
        if(res.code == 1){
          console.log(res)
          document.title = res.data.list.name
          let list = res.data.list
          // for(let k of ['login_bg_img' , 'logo' , 'logo_court' , 'logo_head']){
          //   list[k] = list[k]
          // }
          if( this.system ){
            list.system = this.system;
          }
          // list.system = this.system;
          this.system_info = list ;
          User.temporary('system_info').setBetch(list);
          this.fields.plat_id = this.params.plat_id = this.system_info.id;
          // console.log(this.system_info , list , this.$route.qu
          // ery)
        }
      })

    }



  //获取图片验证码
  changeCodeImg(){
    // this.axios.post(this.$URL.VERIFY ).then((res:any)=>{
    //   console.log(res)
    //   if(res.code==1){
     //   }
    // })
  }
}
