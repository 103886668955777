/*
    清除字段
*/

import { Vue, Component } from 'vue-property-decorator';

@Component
export default class EmptyField extends Vue {
    
    public fields: any = {};

    public onEmptyField(event: any) {
        const { field, default_val } = event.target.dataset;
        if (default_val !== undefined) {
            this.fields[field] = default_val;
        } else if (typeof this.fields[field] === 'string') {
            this.fields[field] = '';
        } else {
            delete this.fields[field];
        }
    }
}