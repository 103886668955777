























































































import { Vue, Component } from 'vue-property-decorator';
import { Tab, Tabs, List, Cell } from 'vant';
import Back from "@/components/Back.vue";

@Component({
    components: {
        'van-tab': Tab,
        'van-tabs': Tabs,
        'van-list': List,
        'van-cell': Cell,
      back:Back
    }
})
export default class Case extends Vue {
    //标签索引
    tabActive: number = 0;
    //列表数据
    list: any = [];
    //当前页码
    page: number = 1;
    //加载状态
    loading: boolean = false;
    //完成状态
    finished: boolean = false;
    
    //获取会议列表
    getMeetingList(index: number, page: number) {
        this.loading = true;
        const progress_id = [10, 6][index];
        this.axios.post(this.$URL.CASE_LIST, { progress_id, page }).then((res: any) => {
            if (res.code == 1) {
                const list = res.data.list;
                if (list.length > 0) {
                    this.page = page;
                    for (let item of list) {
                        this.list.push(item);    
                    }
                } else {
                    this.finished = true;
                }
            }
        }).finally(() => {
            this.loading = false;
        });
    }

    //标签改变
    onTabChange(index: any) {
        this.page = 1;
        this.list = [];
        this.loading = false;
        this.finished = false;
        this.getMeetingList(index, this.page);
    }

    //列表加载
    onLoad() {
        this.getMeetingList(this.tabActive, this.page + 1);
    }

    created() {
        this.getMeetingList(this.tabActive, this.page);
    }
}
