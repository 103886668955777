import axios ,{URL} from '@/axios';

const submitData={
	namespace:true,
	state:()=>({
		// 保存文件
		submitInfo:{},
	}),
	mutations:{
		getSubmitInfo(state:any,data:any){
			if(localStorage.getItem('submitInfo')){
				localStorage.removeItem('submitInfo')
			}
			for(let k in data.step_3.uploadData){
				data.step_3.uploadData[k]=[]
			}
			state.submiInfo={...data}
			localStorage.setItem('submitInfo' , JSON.stringify({...data}))
			return JSON.parse(JSON.stringify({...state,submiInfo:{...data}}))
		}
	}
}
export default submitData;