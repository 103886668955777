import Vue from 'vue';
import VueRouter from 'vue-router';
import { face_info , user } from '@/core/utils';
import {Dialog, Toast} from 'vant';
import axios , { URL } from "@/axios/index";

//账号登录相关
import Guide from '@/pages/Guide.vue';
import Login from '@/pages/Login.vue';
import Register from '@/pages/Register.vue';
import ForgetPassword from '@/pages/ForgetPassword.vue';
import RegisterSuccess from '@/pages/RegisterSuccess.vue';

//隐私政策
import PrivacyPolicy from '@/pages/PrivacyPolicy.vue';
//用户协议
import UserAgreement from '@/pages/UserAgreement.vue';

//身份认证
import Authentication from '@/pages/Authentication.vue';

// 获取人脸核身身份证号
import GetUserCard from '@/pages/GetUserCard.vue';

//导航标签栏
import Home from '@/pages/Home.vue';
import Notice from '@/pages/Notice.vue';
import My from '@/pages/My.vue';

//我的
import UserInfo from '@/pages/UserInfo.vue';
import Settings from '@/pages/Settings.vue';
import AlterPassword from '@/pages/AlterPassword.vue';

//消息
import Message from '@/pages/Message.vue';
import MessageDetails from '@/pages/MessageDetails.vue';

//资讯详情
import NewsDetails from '@/pages/NewsDetails.vue';

//公告
import NoticeDetails from '@/pages/NoticeDetails.vue';

//会议
import Meeting from '@/pages/Meeting.vue';
import MeetingRoom from '@/pages/MeetingRoom.vue';
import MeetingRoom_chongqing from '@/pages/MeetingRoom_chongqing.vue';                  //重庆案件特殊处理，会议投票可重新编辑

//特殊处理会议
import MeetingRoom_DY from '@/pages/case/MeetingRoom_DY.vue';

//案件
import Case from '@/pages/Case.vue';
import CaseDetails from '@/pages/CaseDetails.vue';

//债权申报
import CreditorRights from '@/pages/CreditorRights.vue';
import CreditorRightsDeclare from '@/pages/CreditorRightsDeclare.vue';
import CreditorRightsDetails from '@/pages/CreditorRightsDetails.vue';
import CreditorRightsDeclareList from '@/pages/CreditorRightsDeclareList.vue';
import CreditorDeclareResult from '@/pages/creditorDeclareResult.vue';

//债权人登记
import RegistrationOfCreditors from '@/pages/RegistrationOfCreditors.vue';

// 资金管理
import Funds from '@/pages/funds/Funds.vue';
import FundsElectronicAccount from '@/pages/funds/FundsElectronicAccount.vue';
import ElectronicResult from '@/pages/funds/ElectronicResult.vue';
import CashOut from '@/pages/funds/CashOut.vue';
import AccountFlowDetail from '@/pages/funds/AccountFlowDetail.vue';
import BankAgreement from '@/pages/funds/BankAgreement.vue';
import wx from "weixin-js-sdk";

//签署文件
import ConfirmFileList from "@/pages/ConfirmFileList.vue";
import CreditorFileView1 from "@/pages/CreditorFileView.vue";
import CreditorFileView2 from "@/pages/CreditorFileView2.vue";
import CreditorFileView3 from "@/pages/CreditorFileView3.vue";
import CollectCreditorInfo from "@/pages/CollectCreditorInfo.vue";

//上传附件
import UploadAttachments from "@/pages/UploadAttachments.vue";

Vue.use(VueRouter);

const routes = [
    //首页
    { path: '', meta: { not_need_authen: true }, component: Home },
    //消息
    { path: '/message', component: Message },
    //消息-详情
    { path: '/message/details', component: MessageDetails },
    //资讯详情
    { path: '/news_details', component: NewsDetails },
    //债权人会议
    { path: '/meeting', component: Meeting },
    { path: '/meeting/room', component: MeetingRoom},
	{ path: '/meeting/room_chongqing', component: MeetingRoom_chongqing},
	//特殊处理会议
	{ path:'/meeting/room_DY' , component: MeetingRoom_DY },
    //案件管理
    { path: '/case', component: Case },
    { path: '/case/details', component: CaseDetails },
    //债权申报
    { path: '/creditor_rights', component: CreditorRights },
    //债权申报-详情
    { path: '/creditor_rights/details', component: CreditorRightsDetails },
    //债权申报-申报
    { path: '/creditor_rights/declare', component: CreditorRightsDeclare },
    //债权申报-申报列表
    { path: '/creditor_rights/declare_list', component: CreditorRightsDeclareList },
	// 债权申报-债权审查结果
	{ path: '/creditor_rights/declare_result', component: CreditorDeclareResult },
	
	// 资金管理
	{ path:'/funds' , component:Funds },
	// 开通电子账户
	{ path:'/funds/electronic_account' , component:FundsElectronicAccount },
	// 开户结果
	{ path:'/funds/electronic_result' , component:ElectronicResult },
	// 提现
	{ path:'/funds/cash_out' , component:CashOut },
	// 流水详情
	{ path:'/funds/account_flow_detail' , component:AccountFlowDetail },
	// 银行服务协议
	{ path:'/funds/bank_agreement' , component:BankAgreement } ,
    //公告
    { path: '/notice', meta: { not_need_authen: true }, component: Notice },
    //公告-详情
    { path: '/notice/details', component: NoticeDetails },
    //我的
    { path: '/my', meta: { not_need_authen: true }, component: My },
    //我的-个人信息
    { path: '/my/user_info', component: UserInfo },
    //我的-设置
    { path: '/my/settings', meta: { not_need_authen: true }, component: Settings },
    //我的-设置-修改密码
    { path: '/my/settings/alter_password', meta: { not_need_authen: true }, component: AlterPassword },
    //引导页
    // { path: '/guide', meta: { not_need_login: true }, component: Guide },
    //登录
    { path: '/login', meta: { not_need_login: true }, component: Login },
    //注册
    { path: '/register', meta: { not_need_login: true }, component: Register },
    //注册-成功
    { path: '/register/success', meta: { not_need_login: true }, component: RegisterSuccess },
    //找回密码
    { path: '/forget_password', meta: { not_need_login: true }, component: ForgetPassword },
    //隐私政策
    { path: '/privacy_policy', meta: { not_need_login: true }, component: PrivacyPolicy },
    //用户协议
    { path: '/user_agreement', meta: { not_need_login: true }, component: UserAgreement },
    //身份认证
    { path: '/authentication', meta: { not_need_authen: true }, component: Authentication },
    // 获取人脸核身身份证号
    { path: '/get_user_card', meta: { not_need_authen: true }, component: GetUserCard },
    //债权人登记
    { path: '/registration_of_creditors', meta: { not_need_login: true }, component: RegistrationOfCreditors },
	//签署文件列表
	{ path: '/confirm_file_list',  component: ConfirmFileList,
		// beforeEnter: function (to:any, from:any, next:any)  {
		// 	let message = user.get('file_tips');
		// 	Toast(message)
		// }
	},
	//签署文件详情
	{ path: '/creditor_file_view1',   component: CreditorFileView1 },
	{ path: '/creditor_file_view2',   component: CreditorFileView2 },
	{ path: '/creditor_file_view3',   component: CreditorFileView3 },
	{ path:'/upload_attachments' , component: UploadAttachments },
	{
		path:'/collect_creditor_info',
		component: CollectCreditorInfo
	},
];

let router = new VueRouter({
    mode: 'history',
    routes
});


//前置拦截路由
router.beforeEach(( to , from , next) => {
	// 判断是否携带参数
	let reg = new RegExp("(^|&)" + 'case_num' + "=([^&]*)(&|$)");
	let res = new RegExp("(^|&)" + 'face_result' + "=([^&]*)(&|$)");
	let system = new RegExp("(^|&)" + 'system' + "=([^&]*)(&|$)");

	let g:any = window.location.search.substr(1).match(reg);
	let s:any = window.location.search.substr(1).match(res);
	let p:any = window.location.search.substr(1).match(system);     // 如果是从支付宝小程序打开，会带上 system = ali 的参数；

	if (g != null ) g = decodeURI(g[2]);
	if (s != null ) s = decodeURI(s[2]);
	if (p != null ) p = decodeURI(p[2]);

	const id = user.get('id');
	let id_data:any = face_info.get(id)
	// console.log( id , id_data ,'id_data')

	//如果参数中带有face_result,说明是从人脸小程序跳转回来的页面
	if( s && s === 'success'){
		const info :any = window.location.search.match(/([^?&]+)(?=)/g);
		let data:any={}
		for( let k in info){
			const list = info[k].split('=');
			data[list[0]]= list[1];
			if( list[0] === 'name'){
				data[list[0]]= decodeURIComponent(list[1]);
			}
		}
		Object.assign( id_data , data );
		face_info.set( id , id_data );
		// 登录即签到
		axios.post(URL.MEETING_SIGN_IN + '_zc' , { name: id_data.name , card_id : id_data.card_id }).then((res:any)=>{
			console.log( res , '中成会议登录即签到')
			if(res.code != 1){
				Toast(res.message)
			}
		})
	}

	// console.log( to.meta.not_need_login ,  user.get('token') !== undefined , id_data )
	// next()
    // 判断是否需要登录，以及是否已经登录
    if ( to.meta.not_need_login || user.get('token') !== undefined ) {
		// 特殊处理案件，进入页面前先判断是否需要人脸，是否有人脸记录，否则跳转人脸小程序；
		const token = user.get('token');
		//只有当 用户已登录，是多账号特殊处理案件，没有人脸认证的时候，需要跳转到人脸
		if( user.get('token') !== undefined && id_data && id_data.face_result !== 'success' ){
			wx.miniProgram.navigateTo({ url: '/pages/getUserInfo/getUserInfo?token=' + token })
		}else{
			next();
		}
    } else {
		let path = '';
		if( g ){        //是否有case_num
			path = '/login?case_num=' + g ;
		} else if( s ){     //是否有result
			if(s == 'fail'){
				user.empty();
				user.emit( 'token' , undefined );
				face_info.empty();
				path = '/login'
			}
		}else if( p ){
			path = '/login?system=' + p
		}else{
			path = '/login' ;
		}
		window.location.replace(path);
    }
});

//后置拦截路由
router.afterEach((to, from) => {
    //是否身份认证
    if (!user.get('is_approve') && to.meta.not_need_login !== true && to.meta.not_need_authen !== true) {
        Dialog.alert({
            message: "请先进行债权人身份确认"
        }).then(() => {
            router.replace('/authentication');
        });
    }
})
export default router;