























































































































































































import { Vue, Component } from 'vue-property-decorator';
import { ActionSheet } from 'vant';

@Component({
	components:{
		'van-action-sheet' : ActionSheet
	}
})
export default class BankAgreement extends Vue {
	private show:boolean =false;
	
}
