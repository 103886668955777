




























































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import Conference from '@/components/Conference.vue';
import Aliplayer from '@/components/Aliplayer.vue';
import { Tab, Tabs, RadioGroup, Radio , Checkbox, CheckboxGroup , Popover } from 'vant';
import Chat from '@/components/Chat.vue';
import Message from '@/components/Message.vue';
import DeclareConfirm from '@/components/DeclareConfirm.vue';
import { Dialog } from 'vant';
import Clipboard from 'clipboard';
import wx from 'weixin-js-sdk';
import Back from "@/components/Back.vue";
import { system_info , user , face_info } from '@/core/utils';

@Component({
  components: {
    conference: Conference,
    'ali-player': Aliplayer,
    'van-tab': Tab,
    'van-tabs': Tabs,
    'chat': Chat,
    'message': Message,
    'declare-confirm':DeclareConfirm,
    'van-radio-group': RadioGroup,
    'van-radio': Radio,
    'van-checkbox':Checkbox,
    'van-checkbox-group':CheckboxGroup,
    'van-popover':Popover,
    back:Back,

  }
})
export default class MeetingRoom extends Vue {

  public test:any=[
    {test:'1'}
  ]
  //是否是中诚特殊处理 0否；1是
  private is_zc:number = 0;

  //标签索引
  tabActive: any = 'vote';
  //聊天框高度
  chatHeight: string = '';
  //标签页高度
  tabHeight: string = '';

  //详情信息
  detail: any = {};
  //文件列表
  fileList: any = [];
  //投票事项
  matters: any = [];
  //债权核查
  public inspect:any=[];

  //会议表决项列表
  private voteList:any=[];

  //债委会表决
  private matter_committee:any=null;
  private members:any=[];
  //债委会成员选项
  private members_ids:any=[];

  private changeResult(i:number,val:any){
    if(val == 1){
      this.voteList[i].checkBoxShow = false; //选择反对时隐藏债委会表决
      this.voteList[i].members_ids = []; //清空债委会多选
      this.voteList[i].show_other_input = false;  //隐藏其他人员输入框
    }else{
      this.voteList[i].checkBoxShow = true;
    }
  }

  private getMemberIdsChange(i:any,val:any){
    let other:any ;
    this.voteList[i].members.forEach((item:any)=>{
      if(item.name == '其他人员'){
        other = item.id
        this.voteList[i].otherShow = true;
      }
    })
    this.voteList[i].show_other_input = val.some((item:any)=>{
      return item == other
    })
    if(!this.voteList[i].show_other_input){
      this.voteList[i].other_member = ''
    }
  }

  // 代理债权人列表
  private creditor_list:any=null;
  // 已选中债权人列表
  private creditor_list_select:any=[];
  // 选择代理人
  private selectCreditor( id:number , is_late_voting:any){
    this.axios.post(this.$URL.GET_MATTERS_INFO , { meeting_id:this.meetingId , agent_id:id}).then((res:any)=>{
      // console.log(res , is_late_voting)
      if(res.code == 1){
        if(is_late_voting === '1'){
          this.updateVote(res.data.matters , res.data.matters_committee_info_list);
        }else{
          for(let k in this.creditor_list ){
            if( id == this.creditor_list[k].id){
              this.creditor_list[k].is_select = !this.creditor_list[k].is_select
            }
          }
          this.updateVote( res.data.matters , res.data.matter_committee );
        }
        // console.log( this.matters , '表决事项' , is_late_voting )
      }
    })
  }

  private selectAll:boolean=true;
  // 全选/取消全选
  private creditorSelectAll(){
    if(this.creditor_list.some((item:any)=>{ return item.is_late_voting == '0' })){
      this.selectAll = !this.selectAll ;
      for(let k in this.creditor_list){
        if(this.creditor_list[k].is_late_voting == '0'){
          this.creditor_list[k].is_select = this.selectAll
        }
      }
    }

  }


  private showVoteItem:boolean=false;
  // 展示所有债权人
  private ShowVoteItem(){
    this.showVoteItem = !this.showVoteItem;
    let show:any = document.getElementsByClassName('creditor_vote_list')[0];
    if(this.showVoteItem){
      let h = (((this.creditor_list.length + 1)/4)*50) + 'px';
      show.style.height = h ;
      show.style.overflowY = 'hidden' ;

    }else{
      show.style.height = '50px' ;
      show.style.overflowY = 'hidden' ;
    }
  }

  //会议Id
  meetingId!: number;


  // 获取表决数据
  private updateVote( matters:any , matter_committee?:any , agent?:any){/* type：0首次进入页面 ，1:选择已表决的代理人 */
  // console.log('获取表决数据',matters,matter_committee,agent)

    if( matter_committee){
      let newArr:any = []
      let same:any = []
      matter_committee.forEach((item:any)=>{
        if(item.is_late_voting == 1 && typeof item.members_ids == 'string'){
          item.members_ids = item.members_ids.split(',').map((i:any)=>{return Number(i)});
        }
      })
      matter_committee.forEach((item:any)=>{
        if(item.members_ids.length>0){
          item.members.forEach((i:any) => {
            if (i.name == '其他人员'){
              newArr.push(i)        // 表决选项是否含有其他人员，有则加入到newArr中
              item.otherShow = true
            }
          })
        }
      })
      matter_committee.forEach((item:any)=>{
        if(item.result == 1){  //表决结果选择了反对，直接隐藏多选
          item.checkBoxShow = false
        }
        if(item.members_ids.length>0){
          same = newArr.filter((t:any) => item.members_ids.includes(t.id))  //表决结果去匹配newArr中的id，提取相同项
          if(same.length>0) {
            item.show_other_input = true  //有相同项，证明其他人员选项被选中，展示输入框
          }
        }
      })
    }

    // if( matter_committee){
    //   this.matters_disabled.matter_committee.members = matter_committee.is_late_voting ==='0'?false:true ; //判断当前债委会是否表决过
    //   this.matter_committee={ ...matter_committee }
    //   if(matter_committee.is_late_voting == 1){ //如果已表决过
    //     this.consent_set_value = Number(matter_committee.late_voting.result);
    //     let other = matter_committee.members.find((item:any)=>{ return item.name=='其他人员' });
    //     this.members_ids = matter_committee.late_voting.members_ids.split(',').map((item:any)=>{
    //       if( other && other.id == item ){
    //         this.show_other_input = true;
    //       }
    //       return Number(item)
    //     });
    //   }else{//没表决过的
    //     // if(detail.is_late_voting == '0'){
    //     for(let i=0; i<this.matter_committee.length; i++){
    //       if(i<=this.matter_committee.members.length-1){
    //         this.$set(this.members_ids,i,this.matter_committee.members[i].id)
    //       }
    //     }
    //     // }
    //   }
    // }
    if(agent){
      this.creditor_list = agent.members.map((item:any)=>{
        if(item.is_late_voting == '1'){
          return { ... item , is_select :false}
        }else{
          return { ...item , is_select:true }
        }
      })
    }
  }

  // // 更新会议数据
  private updateMeetingInfo(){
    this.axios.post(this.$URL.MEETING_JOIN, { id:this.meetingId }).then((res: any) => {
      if (res.code == 1) {
        console.log(res)
        const data = res.data;
        this.detail = data.detail;
        this.fileList = data.files;
        this.inspect = data.inspect.map((item:any)=>{ return { ... item , options: item.options.split(',')}});
        this.updateVote( data.matters , data.matters_committee_info_list , data.agent );

      } else {
        this.$notify(res.message);
        this.$router.back();
      }
    });
  }

  //加入会议
  joinMeeting() {
    const id = Number(this.$route.query.id);
    // this.$toast.loading({
    //   message: "正在加入会议中...",
    //   forbidClick: true,
    //   duration: 0
    // });
    this.axios.post(this.$URL.MEETING_JOIN, { id }).then((res: any) => {
      if (res.code == 1) {
        // console.log(res , this.$store.state.staticDatas.firstLoadMeetingList , '会议详情')
        const data = res.data;
        this.detail = data.detail;
        this.fileList = data.files;
        this.is_zc = data.info.is_zc;
        this.inspect = data.inspect.map((item:any)=>{ return { ... item , options: item.options.split(',')}});

        let matterList:any = [];
        matterList = data.matters.map((item:any, index:number) => { return {...item, sort: index+1}})
        data.matters_committee_info_list.forEach((item:any) => {
          if(item.members_ids == ""){
            item.members_ids = []
            item.otherShow = false
            item.show_other_input = false
            item.checkBoxShow= true
          }else{
            item.checkBoxShow= true
          }
        })
        this.voteList = data.matters_committee_info_list.concat(matterList)
        this.voteList.sort((item1:any,item2:any) => {   //债委会表决（matters_committee_info_list）排序优先级比普通表决（matterList）高
          if(item1.sort !== item2.sort){
            return item1.sort - item2.sort;
          }else{
            return item1.sortB - item2.sortB;
          }
        })
        // console.log('合并普通表决和债委会表决',this.voteList)

        this.updateVote(  data.matters , data.matters_committee_info_list , data.agent );

        this.meetingId = id ;

        //会议开始时间
        const start_time = new Date(data.detail.start_time).getTime()
        this.onTimer(start_time)

        if (this.detail.meeting_type_id == 0) {
          //会议模式
          //(this.$refs.conferenceVideo as any).join(data.res);
          this.$toast.fail("暂不支持，请前往电脑端参会");
          this.$router.back();
        } else {
          if(this.detail.status != 0){
            //直播模式
            (this.$refs.aliPlayer as any).join(data.res.config);
          }
          if(this.detail.is_say === 0){
            this.$nextTick(()=>{
              //加入聊天群组
              (this.$refs.chat as any).joinGroup(id, this.$store.state.account.name);
            })
          }
          //加入留言问题
          if(this.detail.message_is_say === 0){
            this.$nextTick(()=>{
              (this.$refs.message as any).joinGroup( id , this.$store.state.account.name );
            })
          }
        }
      } else {
        this.$notify(res.message);
        this.$router.back();
      }
      this.$toast.clear();
    });
  }

  //签到
  signIn() {
    const meeting_id = Number(this.$route.query.id);
    this.$toast.loading({
      message: "正在签到",
      forbidClick: true,
      duration: 0,
      overlay: true
    });
    let data:any = { meeting_id : meeting_id }
    let URL:string = this.$URL.MEETING_SIGN_IN;
    //中诚特殊处理案件，需要加上name和card_id
    const id = user.get('id');
    const id_data = face_info.get(id);
    if( this.is_zc === 1 ){
      URL = URL + '_zc';
      data.name = id_data.name
      data.card_id = id_data.card_id
    }

    this.axios.post( URL , data ).then((res: any) => {
      if (res.code == 1) {
        this.$toast.success("Sign in successfully");
        this.detail.is_sign_in = 1;
      } else {
        this.$toast.fail(res.message);
      }
    });
  }

  private open_file(e:any , url:string){
    let system = system_info.get('system');
    // console.log(system , 'ali')

    if(system == 'ali'){  // 支付宝小程序只支持复制文件链接
      this.onCopy( e , url )
    }else {
      // console.log(`/pages/open_file/open_file?path=${url}&case_id=${ this.detail.case_id }&not_download_file${ this.detail.not_download_pdf }`)
      wx.miniProgram.navigateTo({url: `/pages/open_file/open_file?path=${url}&case_id=${ this.detail.case_id }&not_download_file=${ this.detail.not_download_pdf }`})
    }
  }

  // 复制文件链接
  private onCopy( e:any , url:string ){
    // console.log( e.target , url )
    var clipboard = new Clipboard( e.target , { text: () => 'https://polz.hk/api' + url } )
    clipboard.on('success', () => {
      this.$toast.success("复制成功,请打开浏览器下载文件！");
      // 释放内存
      clipboard.destroy()
    })
    clipboard.on('error', () => {
      // 不支持复制
      this.$toast.fail('该浏览器不支持自动复制！')
      // 释放内存
      clipboard.destroy()
    })
  }

  //投票
  private vote(matters_array: any,committee_array:any) {
    Dialog.confirm({
      title: '提示',
      message: '提交后不可更改，确认是否提交',
    }).then(() => {
          const meeting_id = Number(this.$route.query.id);
          this.$toast.loading({
            message: "正在投票",
            forbidClick: true,
            duration: 0
          });
          //筛选选中的代理人
          let agent:any=[];
          if(this.creditor_list){
            for(let k in this.creditor_list){
              if(this.creditor_list[k].is_select){
                agent.push(this.creditor_list[k].id)
              }
            }
            agent = agent.join(',')
          }else{
            agent=''
          }

          const id = user.get('id');
          const id_data = face_info.get(id);
          let URL:string = this.$URL.MEETING_VOTE;

          let data:any={
            meeting_id: meeting_id ,
            matters_array: JSON.stringify(matters_array),
            committee_array: JSON.stringify(committee_array),
            agent_ids: agent,
          };
          //中诚特殊处理案件，需要加上name和card_id
          if( this.is_zc === 1 ){
            URL = URL + '_zc';
            data.name = id_data.name
            data.card_id = id_data.card_id
          }

          // console.log(data , '提交的投票数据')

          this.axios.post( URL, data ).then((res: any) => {
            if (res.code == 1) {
              this.$toast.success("success");
              this.detail.is_late_voting = '1';
              this.updateMeetingInfo()
            } else {
              this.$toast.fail(res.message);
              setTimeout(()=>{
                this.joinMeeting();
              } , 1500)
            }
          });

        })
        .catch(() => {
          Dialog.close();
        });
  }

  //监听提交投票
  onSubmitVote() {
    this.matters = this.voteList.filter((item:any) => !item.members);
    this.matter_committee = this.voteList.filter((item:any) => item.members);
    // console.log( '提交', this.matters, this.matter_committee )
    let checkedNum = 0;
    let checkedMember = 0
    let checkedInput = 0

    if(this.creditor_list){
      if( this.creditor_list.every((item:any)=>{ return item.is_select == false })){
        Dialog.confirm({
          title: '提示',
          message: '请至少选择一个债权人',
        }).then(()=>{Dialog.close()}).catch(()=>{ Dialog.close() })
        return undefined
      }
    }
    let committeeName:any = ""
    let committeeRealm:any = ""
    if(this.matter_committee){
      this.matter_committee.forEach((item:any)=>{
        if(item.result == null && item.is_consent_set == 1){
          checkedNum = checkedNum + 1
        }
        if(item.result != 1){
          if(item.members_ids.length < item.min_num||item.members_ids.length>item.max_num){
            checkedMember = checkedMember + 1
            committeeName = item.name
            committeeRealm = item.min_num+ '-' + item.max_num
          }
          if(item.show_other_input&&item.other_member==''){
            checkedInput = checkedInput + 1
            committeeName = item.name
          }
        }
      })
    }

    if( this.detail.is_show_waiver > 1 &&  this.matters.some((item:any)=>{ return item.result=='1'&& !item.oppose_reason })){
      Dialog.confirm({
        title: '提示',
        message: '你有表决项的反对理由未输入',
      }).then(()=>{Dialog.close()}).catch(()=>{Dialog.close()})
      return
    }


    const mattersArray =  this.matters.map((val: any) => {
      if(val.vote_btn||typeof (val.vote_btn)=='undefined'){
        checkedNum=checkedNum+1
        if(val.result=="0"||val.result=="1"||val.result=="2"){
          checkedNum = checkedNum - 1
        }
      }
      let data:any={
        matter_id: val.id,
        result: val.result,
      };
      if( this.detail.is_show_waiver > 1) data.oppose_reason = val.oppose_reason

      return data
    });

    const committeeArray=this.matter_committee.map((val :any)=>{
      let data:any = {
        id: val.id,
        name: val.name,
        result: val.result,
        members_ids: val.members_ids.join(','),
        other_member: val.other_member
      }
      return data
    })

    if(checkedNum > 0){
      Dialog.confirm({
        title: '提示',
        message: '你有表决项未选择',
      }).then(()=>{Dialog.close()}).catch(()=>{Dialog.close()})
    }else if(checkedMember > 0){
      Dialog.confirm({
        title: '提示',
        message: `表决项名为“${committeeName}”未正确选择，请选择${committeeRealm}个选项`,
      }).then(()=>{Dialog.close()}).catch(()=>{Dialog.close()})
    }else if(checkedInput > 0){
      Dialog.confirm({
        title: '提示',
        message: `表决项名为“${committeeName}”未填写自荐人员名称`,
      }).then(()=>{Dialog.close()}).catch(()=>{Dialog.close()})
    }else{
      this.vote(mattersArray,committeeArray);
    }
    
  }

  //会议开始倒计时
  private timer:any=null;
  private onTimer(start_time:any){
    let now_time = new Date().getTime();
    if(!this.timer && now_time < start_time ){
      const delay = ((start_time - now_time) + 5000);
      // 定时器最大执行时间
      if(delay < 2147483647 ){
        this.timer = setTimeout(()=>{
          this.$router.go(0)
        } , delay)
      }
    }
  }

  //债权核查成功
  public onDeclareConfirmSuccess(){
    this.$toast.success("success");
    this.updateMeetingInfo()
  }



  //监听选项卡
  onTabChange(name: any) {
    if (name == 'message') {
      (this.$refs.message as any).loadIssuesList();
    }
  }

  created(){
    this.joinMeeting();
  }
  //渐进式APi

  mounted(){
    this.$nextTick(() => {
      const h = (this.$refs.tabs as any).$el.clientHeight;
      this.tabHeight = 'height: calc(' + h + 'px - 1.17333rem)';
      this.chatHeight = 'height: calc(' + h + 'px - 1.41333rem)';
    });
  }

  beforeDestroy() {
    clearTimeout(this.timer);
    // console.log(this.timer)
  }
}
