






























import { Component, Mixins} from "vue-property-decorator";
import {Form, Uploader, Icon, Toast} from 'vant';

import CompressImage from '@/mixins/CompressImage';
import {fileData} from "@/core/utils";
import Back from "@/components/Back.vue";

// Toast

@Component({
  components: {
    'van-uploader' : Uploader,
    'van-icon' : Icon ,
    'van-form' : Form ,
    back:Back,

  }
})
export default class UploadAttachments extends Mixins(CompressImage){

  private file_list:any=[];

  private show_list:any=[];

  created(){
    this.getFileList();
  }


  // 加载已上传文件
  private getFileList(){
    Toast.loading({
      message: 'Loading...',
      forbidClick: true,
      duration: 0
    });
    this.axios.post(this.$URL.CREDITOR_FILES_LIST ).then((res:any)=>{
      Toast.clear();
      console.log( res , '已上传文件列表')
      if(res.code == 1){
        this.show_list = res.data.list.files_info === ''? []:JSON.parse(res.data.list.files_info);
      }
    })
  }


  private async onIdCardUpload(file:any ){
    // console.log( file , info )
    let src = await this.uploadCardImg(file)
    // const name_list = file.file.name.split('.');
    this.$set( this.show_list , this.show_list.length , { name:file.file.name , url: src , type:'file' });
    console.log( this.file_list ,  this.show_list ,'123123')
  }

  private uploadCardImg(file:any){
    Toast.loading({
      message: 'Loading...',
      forbidClick: true,
      duration: 0
    });
    return new Promise((resolve:any)=>{
      this.$uploadFile.post(this.$URL.UPLOAD_FILE, fileData(file.file)).then((res:any)=>{
        Toast.clear();
        if(res.code == 1){
          // console.log(res.data.src, ' 图片地址')
          resolve( this.$URL.BASE_URL + res.data.src)
        }
      })
    })
  }

  private submit(){
    Toast.loading({
      message: 'Loading...',
      forbidClick: true,
      duration: 0
    });

    this.axios.post(this.$URL.CREDITOR_FILE_REPORT, {
      files_info:JSON.stringify(this.show_list)
    }).then((res:any)=>{
      Toast.clear();
      if(res.code == 1){
        Toast.success({
          message: 'success',
          duration: 1000
        });
        this.$nextTick(()=>{
          this.getFileList();
        })
      }
    })

  }


}
