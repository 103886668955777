var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alter_password"},[_c('back',{on:{"routerBack":function($event){return _vm.$router.back()}}}),_c('div',{staticClass:"page-header"}),_c('van-form',{staticClass:"info_form",attrs:{"label-width":"2.8rem"},on:{"submit":_vm.onAlterPassword}},[_c('van-field',{attrs:{"type":"password","name":"old_pwd","label":"Old login password","placeholder":"Please enter your old login password","format-trigger":"onBlur","autocomplete":"off","rules":[{ required: true, message: 'Please enter your old login password' }]},model:{value:(_vm.fields.old_pwd),callback:function ($$v) {_vm.$set(_vm.fields, "old_pwd", $$v)},expression:"fields.old_pwd"}}),_c('van-field',{attrs:{"type":"password","name":"password","label":"New password","placeholder":"Please enter your new password","format-trigger":"onBlur","autocomplete":"off","rules":[
                    { required: true, message: 'Please enter your new password' },
                    { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d$@$!%*?&]{6,15}$/, message: 'Passwords are 6-15 characters long, with at least one number and one letter' }
                ]},model:{value:(_vm.fields.password),callback:function ($$v) {_vm.$set(_vm.fields, "password", $$v)},expression:"fields.password"}}),_c('van-field',{attrs:{"type":"password","name":"new_pwd","label":"Enter the new password again","placeholder":"Please enter your new password again","format-trigger":"onBlur","autocomplete":"off","rules":[
                    { required: true, message: 'Please enter your new password again' },
                    {
                        validator: function (value, rule) { return _vm.fields.password == _vm.fields.new_pwd },
                        message: '两次输入密码不一致，请重新输入！' 
                    }
                ]},model:{value:(_vm.fields.new_pwd),callback:function ($$v) {_vm.$set(_vm.fields, "new_pwd", $$v)},expression:"fields.new_pwd"}}),_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit"}},[_vm._v("Edit")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }