  /*
    静态数据
  */

import axios, { URL } from '@/axios';

 const staticDatas = {
    namespaced: true,
    state: () => ({
      //选择项
        select_options: {},
      //模板文件
        template_files: {},

        firstLoadMeetingList:false,
    }),
    mutations: {
        init(state: any, data: any) {
            state.select_options = data.options;
            state.template_files = data.templateFiles;
        },
        first_login( state:any , data:any){
            state.firstLoadMeetingList = data
        },
    },
    actions: {
      init(context: any) {
        axios.post(URL.STATIC_DATAS).then((res: any) => {
          if (res.code == 1) {
            context.commit('init', res.data);
          }
        });
      }
    }
};

export default staticDatas;