/*
    下载文件
*/
import { URL } from '@/axios/URL';
import Download from '@/core/Download';

export const download = new Download({
    baseURL: URL.BASE
});

declare module "vue/types/vue" {
    interface Vue {
        $download: Download
    }
}