/*
    账户
*/

import { user , face_info } from '@/core/utils';
import axios, { URL } from '@/axios';

//需要的账户信息
export const NEED_ACCOUNT_INFO = ['id', 'level', 'name', 'profile', 'mobile', 'com_name', 'version', 'is_approve', 'bank_flag'];

const account = {
    namespaced: true,
    state: () => user.getBetch(NEED_ACCOUNT_INFO),
    mutations: {
        //设置信息
        setInfo(state: any, info: any) {
            user.setBetch(info);
            user.emit('token', info.token);
            user.getBetch(NEED_ACCOUNT_INFO, state);
        },
        //设置认证状态
        setAuthen(state: any, status: boolean) {
            state.is_approve = status;
            user.set('is_approve', status);
        },
        //退出登录
        exitLogin() {
            user.empty();
            user.emit( 'token' , undefined );
            window.location.replace('/login');
        }
    },
    actions: {
        getInfo(context: any) {
            axios.post(URL.ACCOUNT_INFO).then((res: any) => {
                console.log( res, 'accoun info')
                if (res.code == 1) {
                    context.commit( 'setInfo' , res.data.account);
                }
            });
        }
    }
};

export default account;