














import { Vue , Component} from "vue-property-decorator";
@Component({
  components:{
  }
})
export default class signCanvas3 extends Vue {

  private isCrop:boolean=false;

  private lineWidth:any=''

  private lineColor:any=''

  private bgColor:any=''


  handleGenerate() { // 获取base64
    var _this = this;
    (_this.$refs as any).esign.generate().then((res:any) => {
      console.log( res , 'base64')
      this.$emit('confirm' , res)
      // 转成文件
      // var blob = _this.dataURLtoBlob(res)
      // var tofile = _this.blobToFile(blob, '签名.jpg')
      // setTimeout(async () => {
      //   const formData = new FormData()
      //   formData.append('file', tofile, tofile.name)
      //   formData.append('fileType', '9')
      //   // ajax 请求
      //   console.log( formData ,  blob , tofile ,  '文件' )
      // })
    }).catch(() => {
      // 画布没有签字时提示
      this.$notify({
        message: 'fail',
        type: 'warning'
      });
    })
  }

  // 将base64转换为blob
  dataURLtoBlob(dataurl:any) {
    var arr = dataurl.split(',')
    var mime = arr[0].match(/:(.*?);/)[1]
    var bstr = atob(arr[1])
    var n = bstr.length
    var u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

  // 将blob转换为file
  blobToFile(theBlob:any, fileName:any) {
    theBlob.lastModifiedDate = new Date()
    theBlob.name = fileName
    return theBlob
  }

  handleReset() { // 清除
    if (this.$refs.esign) {
      (this.$refs as any).esign.reset();
    }
    var obj:any = document.getElementById("canvas");
    obj.style.backgroundColor = "#fff";
  }

  handleBack(){
    this.$emit('back' )
  }
}
