









































































































































import { Vue , Component } from 'vue-property-decorator';
import { List, Cell , Field , DatetimePicker , Popup } from 'vant';
import Back from "@/components/Back.vue";
@Component({
	components:{
		'van-list': List,
		'van-cell': Cell,
		'van-field' : Field ,
		'van-popup' : Popup ,
		'van-datetime-picker' : DatetimePicker,
    back:Back
	}
})
export default class AccountFlowDetail extends Vue {
	// 列表
	private list:any=[];
	// 页码
	private page:number=1;
	private loading:boolean = false;
	private finished:boolean=false;
	
	private queryParams:any={
		BeginDate: '' ,
		EndDate:this.timer(new Date()),
	}
	// 时间格式转换
	private timer(time:any){
		const year = time.getFullYear();
		const month = (time.getMonth()+1) > 9 ? (time.getMonth()+1) :( '0'+(time.getMonth()+1));
		const day = time.getDate()>9?time.getDate():('0' + time.getDate());
		return String(year) + String(month) + String(day)
	}
	
	// 起始日期
	private beginShow:boolean=false;
	private beginConfirm(value:any){
		this.queryParams.BeginDate = this.timer(value);
		this.query();
		this.beginShow = false;
	}
	// 截止日期
	private endShow:boolean = false ;
	private endConfirm(value:any){
		this.queryParams.EndDate = this.timer(value);
		this.query()
		this.endShow = false;
	}
	
	// 查询
	private query(){
		if(this.queryParams.BeginDate != '' && this.queryParams.EndDate!=''){
			this.getAccountFlowList(1)
		}
	}
	
	private getAccountFlowList(page:number){
		this.loading = true ;
		this.axios.post(this.$URL.FUNDS_FLOW_DETAIL , {...this.queryParams }).then((res:any)=>{
			console.log(res)
			if(res.data.returnCode == '000000'){
				let data = res.data
				if(data){
					this.page = page;
					for(let k of data){
						this.list.push(k)
					}
					this.loading=false;
				}else{
					this.finished = true;
					this.loading = false ;
				}
			}else{
				this.$toast.fail(res.data.returnMsg)
				this.finished = true;
				this.loading = false;
			}
		}).finally(()=>{
			this.loading=false;
		})
	}
	
	private onLoad(){
		this.getAccountFlowList(this.page+1);
	}
	
	created(){
		this.getAccountFlowList(this.page);
	}
}
