import Vue from 'vue';
import App from './App.vue';
import less from 'less';
import store from '@/store';
import router from '@/router';
import VueAxios from 'vue-axios';
import 'amfe-flexible/index.js';
import 'vant/lib/index.less';
import { user } from '@/core/utils';
import { NavBar, Notify, Dialog, Toast  } from 'vant';
import axios, { URL, uploadFile, download , noSign } from '@/axios';
import wx from 'weixin-js-sdk';

Vue.config.productionTip = false;

// import SignCanvas from "sign-canvas";
// Vue.use(SignCanvas);

import VueEsign from 'vue-esign';

Vue.use(VueEsign);

Vue.use(less);
Vue.use(NavBar)
    .use(Notify)
    .use(Dialog)
    .use(Toast);
	// .use(Popover);
Vue.use(VueAxios, axios);
Vue.use(wx);

Vue.prototype.$URL = URL;
Vue.prototype.$user = user;
Vue.prototype.$download = download;
Vue.prototype.$uploadFile = uploadFile;
Vue.prototype.$noSign = noSign

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')
