

































































































































import { Vue, Component } from 'vue-property-decorator';
import {Tabbar, TabbarItem, Swipe, SwipeItem , Dialog } from 'vant';
// import { bank_account } from '@/core/utils.ts';
import {face_info, system_info, user} from '@/core/utils.ts';
import wx from "weixin-js-sdk";

@Component({
    components: {
        'van-tabbar': Tabbar,
        'van-tabbar-item': TabbarItem,
        'van-swipe': Swipe,
        'van-swipe-item': SwipeItem
    }
})
export default class Home extends Vue {
    //标签索引
    active: number = 0;

    //消息列表
    messageList: any = [];

    //资讯列表
    newsList: any = [];

    //获取会议列表
    meeting_list:any=[];

    //小程序链接
    mini_declare_url:string='';

    //获取消息列表
    getMessageList() {
        this.axios.post(this.$URL.MESSAGE_LIST, { type: 2, page: 1 }).then((res: any) => {
          // console.log(res , '消息列表')
          if (res.code == 1) {
            this.mini_declare_url = res.data.url_link;
            this.messageList = res.data.list;
          }
        });
    }

    //获取资讯列表
    getNewsList() {
        this.axios.post(this.$URL.MESSAGE_LIST, { type: 0, page: 1 }).then((res: any) => {
            if (res.code == 1) {
              // this.newsList = res.data.list
              for(let k in res.data.list){
                if( Number(k) < 3 ){
                  this.newsList.push(res.data.list[k])
                }
              }
            }
        });
    }

    private plat_name:string = '';

    // 获取参数
    private getQueryString(name:any){
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return decodeURI(r[2]);
      }
      return undefined;
    }

  //获取会议列表
  private getMeetinglist(){
    this.axios.post(this.$URL.MEETING_DETAIL, { page:1 , limit: 999 , case_id: 'mobile' }).then((res: any) => {
      // console.log(res , 'meeting_list')
      if(res.code == 1){
        let list = res.data.list
        this.meeting_list = list.filter((item:any)=>{
          return item.status!= 2
        })
      }
    })
  }

  //跳转到申报小程序
  private jumpToMiniDeclare(){
    const plat_id = system_info.get('id');
    if([ 1 , 2 , 3 , 20 , 23].indexOf(plat_id) > -1 ){
      wx.miniProgram.navigateTo({url:'/pages/declare_medium/declare_medium'})
    }else{
      Dialog.confirm({
        title: '提示',
        message: `暂未开放`,
      }).then(()=>{Dialog.close()}).catch(()=>{Dialog.close()})
    }
  }

  private case_num:any='';
  created() {
    // let face_result = window.localStorage.getItem('face_result');
    // if(!face_result){
    //   this.$router.replace('/login')
    // }

    const { id , token } = user.getBetch(['id', 'token']);
    let id_data:any = face_info.get( id );

    //id_data 不为空的时候，要判断face_result,
    if( id_data && id_data.face_result !== 'success' ){
      wx.miniProgram.navigateTo({ url: '/pages/getUserInfo/getUserInfo?token=' + token })
    }

    this.case_num = this.getQueryString('case_num');

    this.plat_name = system_info.get('name');
    this.getMessageList();
    this.getNewsList();
    this.getMeetinglist();
  }
}
