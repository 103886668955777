






import { Component , Vue } from "vue-property-decorator";

@Component
export default class Back extends Vue{
  private back(){
    this.$emit('routerBack' )
  }
}
