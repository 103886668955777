










































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Back from "@/components/Back.vue";

const account = namespace('account');

@Component({
  components:{
    back:Back

  }
})
export default class Settings extends Vue {
    //手机号码
    @account.State('mobile')
    mobile!: string;

    //Log out
    exitLogin(id: number) {
        this.$toast.loading({
            message: "正在退出",
            forbidClick: true,
            duration: 0
        });
        this.axios.post(this.$URL.EXIT_LOGIN, { id }).then((res: any) => {
            if (res.code == 1) {
                this.$store.commit('account/exitLogin');
            } else {
                this.$toast.fail(res.message);
            }
        });
    }

    //监听Log out
    onExitLogin() {
        this.exitLogin(this.$store.state.account.id);
    }
}
