/*
    机密
*/

import { Md5 } from 'ts-md5';
import JsEncrypt from 'jsencrypt';
import { ɵn, AES, ɵu, ɵr } from 'crypto-ts';

//加密结果
interface EncryptResult {
    sky: string,
    body: string,
    sign: string
}

export default class Secret {
    //字符映射
    private readonly charMap: string[] = [];

    //rsa公钥
    private readonly rsaPublicKey: string;
    //rsa私钥
    private readonly rsaPrivateKey: string;

    //签名钥匙
    private readonly signKey: string;

    //初始字符映射
    private initCharMap() {
        //字符范围
        const charRange = [
            [48, 57],   //数字
            [65, 90],   //大写字母
            [97, 122]   //小写字母
        ];
        for (let rang of charRange) {
            const [ from, to ] = rang;
            for (let i = from; i <= to; ++i) {
                this.charMap.push(String.fromCharCode(i));
            }
        }
    }

    constructor(signKey: string, rsaPublicKey: string, rsaPrivateKey: string) {
        this.signKey = signKey;
        this.rsaPublicKey = rsaPublicKey;
        this.rsaPrivateKey = rsaPrivateKey;
        this.initCharMap();
    }

    //生成密钥
    private genKey(keyStr: string): string {
        let jse = new JsEncrypt();
        jse.setPublicKey(this.rsaPublicKey);
        return jse.encrypt(keyStr);
    }

    //生成主体
    private genBody(data: any, keyStr: string): string {
        const datastr = JSON.stringify(data);
        const key = ɵn.parse(keyStr);
        const srcs = ɵn.parse(datastr);
        let encrypted = AES.encrypt(srcs, key, { mode: ɵu, padding: ɵr });
        return encrypted.toString();
    }

    //生成签名
    private genSign(data: any): string {
        let keys = [];
        for (let k in data) {
            const d = data[k];
            if (d != '' && typeof d != 'object' && d != 'sign') {
                keys.push(k);
            }
        }
        let str = '';
        keys = keys.sort();
        for (const k of keys) {
            str += `${k}=${data[k]}&`;
        }
        str += 'key=' + this.signKey;
        return (Md5.hashStr(str) as string).toUpperCase();
    }

    //随机整数
    private randomInt(from: number, to: number): number {
        return Math.floor(Math.random() * (to - from + 1) + from);
    }

    //随机字符串
    private randomStr(min: number, max: number = min): string {
        const length = min < max ?  this.randomInt(min, max): min;
        const range = this.charMap.length - 1;
        let str = '';

        for (let i = 0; i < length; ++i) {
            str += this.charMap[this.randomInt(0, range)];
        }
        return str;
    }

    //是否是加密结果
    public static isEncryptResult(data: any): boolean {
        const keyMap = ['sky', 'body', 'sign'];
        let count = 0;
        for (let k of keyMap) {
            if (data[k] !== undefined) {
                ++count;
            }
        }
        if (count == keyMap.length) {
            return true;
        }
        return false;
    }

    //加密
    public encrypt(data: any): EncryptResult {
        const keyStr = this.randomStr(16);
        return {
            sky: this.genKey(keyStr),
            body: this.genBody(data, keyStr),
            sign: this.genSign(data)
        };
    }

    //解密
    public decrypt(eres: EncryptResult): any {
        let jse = new JsEncrypt();
        jse.setPublicKey(this.rsaPrivateKey);
        const key = ɵn.parse(jse.decrypt(eres.sky));
        const message = ɵn.stringify(AES.decrypt(eres.body, key, { mode: ɵu, padding: ɵr }));
        const data = JSON.parse(message);
        //验证签名
        const sign = this.genSign(data);
        if (sign == eres.sign) {
            return data;
        }
        return null;
    }
} 