


























































import { Vue , Component } from 'vue-property-decorator';
import { List, Cell  } from 'vant';
import Back from "@/components/Back.vue";
@Component({
  components:{
    'van-list': List,
    'van-cell': Cell,
    back:Back
  }
})
export default class Funds extends Vue{
  // 列表
  private list:any=[];
  // 完成状态
  private finished :boolean = false;
  //加载状态
  loading: boolean = false;

  page:any=1;

  private onElectronicAccount(){
    this.$router.push({
      path:'/funds/electronic_account',
    })

  }




  private getAccountList(page:any){
    this.loading =true;
    this.axios.post(this.$URL.MINI_ACCOUNT_LIST , { page :page }).then((res:any)=>{
      console.log(res)
      if(res.code == 1){
        const list = res.data.list
        if(list.length > 0){
          this.page = page ;
          for(let k in list){
            this.list.push(list[k])
          }
        }else{
          this.finished = true ;
        }
      }
    }).finally(() => {
      this.loading = false;
    });
  }

  private onLoad(){
    this.getAccountList(this.page + 1);
  }

  created(){
    this.getAccountList(this.page);
  }
}
