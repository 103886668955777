




































































































































































































import {Component, Vue} from "vue-property-decorator";
import Back from "@/components/Back.vue";
import { Form, Toast , Field , RadioGroup , Radio } from 'vant';
// import Sign from "@/components/sign/index.vue";
import signDialog from '@/components/signCanvas2.vue'
import signDialog3 from '@/components/signCanvas3.vue'


@Component({
  components:{
    back:Back,
    'van-form': Form,
    'van-field': Field,
    'van-radio-group': RadioGroup,
    'van-radio': Radio,
    'sign-dialog':signDialog,
    'sign-dialog-3':signDialog3,

    // sign: Sign
  }
})
export default class CreditorFileView extends Vue{

  //签字弹窗
  private signDialog:boolean=false;
  private isSign:boolean=false;

  // 输入框弹窗
  private dialogVisible:boolean = false;
  private dialog_key:string='';

  //输入框内容
  private params:any={
    input:''
  }

  private dialogShow:boolean=false;

  private get inputType(){
    // let key = this.dialog_key;
    // if(['info_1' , 'info_2', 'info_3', 'info_4', 'info_5', 'info_6', 'info_7', 'info_8', 'info_9'].includes(key)){
      return true
    // }else {
    //   return false
    // }
  }

  //详情
  private detail:any={
    sign:'',
  }

  created(){
    Toast.loading({
      message: 'Loading...',
      forbidClick: true,
      duration: 0
    });
    this.axios.post( this.$URL.CREDITOR_FILE_LIST ,{ type: 1 }).then((res:any)=>{
      Toast.clear();
      console.log(res , '文件列表')
      if(res.code == 1){
        let list = res.data.list;
        const id = this.$route.query.id;
        for(let k in list){
          if( list[k].id == id){
            this.detail = list[k];
          }
        }
        this.detail.sign_path = this.detail.sign;
        if( this.detail.sign_path ==''){
          this.isSign = false;
        }
      }
    })
  }


  // 需要输入的文本
  // private showInputInfo:any={}

  //
  private onEnter(key:any){
    // if( this.detail.is_submit === 0){
      if( key==='sign'){
        this.signDialog = true;
      }else{
        this.dialog_key = key;
        this.dialogVisible = true ;
        this.params.input = this.detail.info_list[key];
        this.dialogShow = true
      }
    // }
    console.log( key , this.signDialog )
  }

  private cancel(){
    this.dialogVisible = false;
    this.params.input = '';
  }

  private save(){
    this.detail.info_list[this.dialog_key] = this.params.input;
    this.$set( this.detail.info_list, this.dialog_key, this.params.input)
    this.$forceUpdate()
    this.dialogVisible = false;
    this.params.input = '';
    console.log( this.detail , 'detail' )
  }

  //签字
  private handleReceiverSign(src:any){
    // console.log( src , '签字')
    this.detail.sign_path = src;
    this.signDialog = false ;
    this.isSign = true;
  }


  //提交
  private submitForm(){
    let data:any={};
    for(let k in this.detail.info_list){
      data[k] = this.detail.info_list[k]
    }
    data.file_id = this.detail.id;
    data.sign_path= this.detail.sign_path;
    Toast.loading({
      message: 'Loading...',
      forbidClick: true,
      duration: 0
    });
    console.log( data , '提交的数据')
    this.axios.post(this.$URL.EDIT_CREDIOT_FILE, data).then((res:any)=>{
      Toast.clear();
      if(res.code == 1){
        // this.$message.success('success')
        Toast.success('success')
        this.$router.replace('/confirm_file_list?type=1')
      }else{
        Toast.fail(res.message)
      }
    })
  }

}
