



































































































































import { Component, Mixins } from 'vue-property-decorator';
import EmptyField from '@/mixins/EmptyField';
import { Checkbox, Form, Field, Button } from 'vant';
import SendVerifyCode from '@/components/SendVerifyCode.vue';
import { system_info } from '@/core/utils.ts';
import Back from '@/components/Back.vue';

@Component({
    components: {
        'van-form': Form,
        'van-field': Field,
        'van-button': Button,
        'van-checkbox': Checkbox,
        'send-verify-code': SendVerifyCode,
      back:Back
    }
})
export default class Register extends Mixins(EmptyField) {
    //请求字段
    fields: any = {
        type: 2,
        name:'',
        mobile: '',
        sms_code: '',
        password: '',
        new_pwd: '',
        is_pass: false,
        plat_id:'',
        case_id:'',
    };

    //注册
    register(fields: any): void {
      // console.log(this.system_info)

      if (fields.is_pass !== true) {
            this.$toast.fail("请阅读并同意《隐私政策》和《用户协议》");
            return undefined;
        }
        this.$toast.loading({
            message: "正在注册",
            forbidClick: true,
            duration: 0
        });
        this.axios.post(this.$URL.REGISTER , fields).then((res: any) => {
            if (res.code == 1) {
                this.$toast.clear();
                this.$router.push('/register/success');
            } else {
                this.$toast.fail(res.message);
            }
        });
    }

    //监听注册
    onRegister() {
        this.register(this.fields);
    }

    private system_info:any={};
    created(){
      this.system_info = system_info.getBetch( ['logo' , 'name' , 'id']) ;
      this.fields.plat_id = this.system_info.id ;
    }
}
