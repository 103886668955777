


























































































































































import {Component, Vue} from "vue-property-decorator";
import Back from "@/components/Back.vue";
import { Form, Toast , Field } from 'vant';

@Component({
  components:{
    back:Back,
    'van-form': Form,
    'van-field': Field,
  }
})
export default class CreditorFileView extends Vue{

  // 输入框弹窗
  private dialogVisible:boolean = false;
  private dialog_key:string='';

  //输入框内容
  private params:any={
    input:''
  }

  private dialogShow:boolean=false;

  //详情
  private detail:any={}

  created(){
    Toast.loading({
      message: 'Loading...',
      forbidClick: true,
      duration: 0
    });
    this.axios.post( this.$URL.CREDITOR_FILE_LIST ).then((res:any)=>{
      Toast.clear();
      console.log(res , '文件列表')
      if(res.code == 1){
        let list = res.data.list;
        const id = this.$route.query.id;
        for(let k in list){
          if( list[k].id == id){
            this.detail = list[k];
          }
        }
        for(let k  in this.detail.info_list ){
          this.showInputInfo[k] = false
          this.$set( this.showInputInfo , k , false)
        }
        console.log( this.showInputInfo ,'showInputInfo')
      }
    })
  }


  // 需要输入的文本
  private showInputInfo:any={}

  //
  private onEnter(key:any){
    if( this.detail.is_submit === 0){
      this.dialog_key = key;
      this.dialogVisible = true ;
      this.params.input = this.detail.info_list[key];
      this.dialogShow = true
    }
  }

  private cancel(){
    this.dialogVisible = false;
    this.params.input = '';
  }

  private save(){
    this.detail.info_list[this.dialog_key] = this.params.input;
    this.$set( this.detail.info_list, this.dialog_key, this.params.input)
    this.$forceUpdate()
    this.dialogVisible = false;
    this.params.input = '';
    console.log( this.detail , 'detail')
  }

  //提交
  private submitForm(){
    let data:any={};
    for(let k in this.detail.info_list){
      data[k] = this.detail.info_list[k]
    }
    data.file_id = this.detail.id;
    Toast.loading({
      message: 'Loading...',
      forbidClick: true,
      duration: 0
    });
    this.axios.post(this.$URL.EDIT_CREDIOT_FILE, data).then((res:any)=>{
      Toast.clear();
      if(res.code == 1){
        // this.$message.success('success')
        Toast.success('success')
        this.$router.replace('/confirm_file_list')
      }else{
        Toast.fail(res.message)
      }
    })

  }

}
