import axios, { AxiosStatic } from 'axios';
import store from '@/store';
import Secret from '@/core/Secret';
import { URL } from '@/axios/URL';
import { secret } from '@/core/utils';
import { Toast } from 'vant';

//上传文件
export const uploadFile = axios.create({
    baseURL: URL.BASE,
    headers: { 'Content-Type': 'multipart/form-data' }
});

//拦截响应解密
uploadFile.interceptors.response.use((res) => {
    let resData = res.data;
    if (resData.data && Secret.isEncryptResult(resData.data)) {
        resData.data = secret.decrypt(resData.data) || {};
    }

    //登录过期
    if (resData.code == -3) {
        store.commit('account/exitLogin');
    }

    //console.log(res.config.url);
    //console.table(resData);

    return resData;
}, (error): any => {
    Toast.fail("网络连接失败");
    return Promise.reject(error);
});

declare module "vue/types/vue" {
    interface Vue {
        $uploadFile: AxiosStatic
    }
}