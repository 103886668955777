














import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Aliplayer extends Vue {
  //启用
  private enabled: boolean = false;

  //加入
  public join(options: any) {
    (window as any).playerOptions = options;
    this.enabled = true;
  }

  destroyed() {
    (window as any).playerOptions = undefined;
  }
}
