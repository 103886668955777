





























import {Component, Vue} from "vue-property-decorator";
import Back from "@/components/Back.vue";
import {Toast} from "vant";
@Component({
  components:{
    back:Back
  }
})
export default class ConfirmFileList extends Vue{

  private loading:boolean=false;

  private fileList:any=[];

  created(){
    let data:any={};
    if(this.$route.query.type){
      data.type = this.$route.query.type;
    }
    Toast.loading({
      message: 'Loading...',
      forbidClick: true,
      duration: 0
    });
    this.axios.post( this.$URL.CREDITOR_FILE_LIST , data ).then((res:any)=>{
      Toast.clear();
      this.loading = false;
      console.log(res , '文件列表')
      if(res.code == 1){
        this.fileList = res.data.list;
      }else {
        Toast.fail(res.message);
      }
    })
  }
}
