


















































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { Step, Steps } from 'vant';
import { ImagePreview } from 'vant';
import { namespace } from 'vuex-class';
import Back from "@/components/Back.vue";

const staticDatas = namespace('staticDatas');
@Component({
    components: {
        'van-step': Step,
        'van-steps': Steps,
      back:Back

    }
})
export default class CaseDetails extends Vue {
    //详情
    detail: any = {};

	//证件类型选项
	@staticDatas.State(state => {state.select_options.card_type_ids})
	private cardTypeOptions!: any;

    //获取详情
    getDetail(declare_id: number) {
        this.axios.post(this.$URL.CREDITOR_DECLARE_INFO, { declare_id }).then((res: any) => {
            if (res.code == 1) {
                this.detail = res.data.info;
				this.axios.post(this.$URL.STATIC_DATAS).then((res:any)=>{
					if(res.code==1){
						const card=this.detail.card_type
						res.data.options.card_type_ids.forEach((item:any)=>{
							if(item.id==card){
								this.detail.card_type=item.name
							}
						})
					}
				})
            } else {
                this.$notify(res.message);
            }
        });
    }
    
    //图片预览
    imgPreview(images: string[], index: number) {
        ImagePreview({
            images,
            startPosition: index,
            closeable: true
        });
    }

    created() {
        this.getDetail(Number(this.$route.query.id));
    }
}
