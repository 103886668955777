







































































import { Vue, Component } from 'vue-property-decorator';
import { Tabbar, TabbarItem, List, Cell } from 'vant';
@Component({
    components: {
        'van-tabbar': Tabbar,
        'van-tabbar-item': TabbarItem,
        'van-list': List,
        'van-cell': Cell
    }
})
export default class Notice extends Vue {
    //标签索引
    active: number = 1;

    //查询案号
    caseNum: string = '';

    //列表数据
    list: any = [];
    //当前页码
    page: number = 1;
    //加载状态
    loading: boolean = false;
    //完成状态
    finished: boolean = false;
    
    //获取列表
    getList(page: number) {
        this.loading = true;
        this.axios.post(this.$URL.NOTICE_LIST, { num: this.caseNum, page }).then((res: any) => {
            // console.log(res , '公告列表')
            if (res.code == 1) {
                const list = res.data.list;
                if (list.length > 0) {
                    this.page = page;
                    for (let item of list) {
                        this.list.push(item);
                    }
                } else {
                    this.finished = true;
                }
            }
        }).finally(() => {
            this.loading = false;
        });
    }

    //列表加载
    onLoad() {
        this.getList(this.page + 1);
    }

    //监听搜索
    onSearch() {
        this.loading = false;
        this.finished = false;
        this.list = [];
        this.getList(1);
    }

    created() {
        this.getList(this.page);
    }
}

