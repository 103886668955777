

























































































import { Vue, Component } from 'vue-property-decorator';
import { Form, Field, Button } from 'vant';
import SendVerifyCode from '@/components/SendVerifyCode.vue';

@Component({
    components: {
        'van-form': Form,
        'van-field': Field,
        'van-button': Button,
        'send-verify-code': SendVerifyCode
    }
})
export default class RegistrationOfCreditors extends Vue {
    //标题
    title: string = '';

    //请求字段
    fields: any = {
        name: '',
        mobile: '',
        sms_code: '',
        address: '',
        wechat: ''
    };

    //清除表单
    clearForm() {
        for (let k in this.fields) {
            this.fields[k] = '';
        }
    }
    //提交
    onSubmit(values: any) {
        this.$toast.loading({
            message: "正在提交",
            forbidClick: true,
            duration: 0
        });

        this.axios.post(this.$URL.ADD_CREDITOR_USER_INFO, { case_code: this.$route.query.v, ...values}).then((res: any) => {
            if (res.code == 1) {
                this.$toast.success("success");
                this.clearForm();
            } else {
                this.$toast.fail(res.message);
            }
        });
    }

    //获取标题
    getTitle() {
        this.axios.post(this.$URL.CREDITOR_RIGHT_REGISTER_TITLE, { case_code: this.$route.query.v }).then((res: any) => {
            if (res.code == 1) {
                const info = res.data.info;
                if (info !== null) {
                    this.title = info.title;
                } else {
                    this.$toast.fail({
                        message: "案件不存在",
                        forbidClick: true,
                        duration: 0,
                        overlay: true
                    });
                }
            }
        });
    }

    created() {
        this.getTitle();
    }
}
