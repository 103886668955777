/*
    事件管理器
*/

//事件映射
interface EventMap {
    [name: string]: {
        _this: object,
        isOnce: boolean,
        callback: Function
    }[]
}

export default class EventManager {

    //事件映射
    private _eventMap: EventMap = {};

    //触发事件
    public emit(name: string, ...data: any): void {
        let events = this._eventMap[name];
        if (events) {
            let indexs = [];
            for (let i = 0; i < events.length; ++i) {
                const event = events[i];
                if (event.callback.length == data.length) {
                    event.callback.bind(event._this || this, ...data)();
                    if (event.isOnce) {
                        indexs.push(i);
                    }
                }
            }
            for (let i = indexs.length - 1; i >= 0; --i) {
                events.splice(i, 1);
            }
        }
    }

    //监听事件
    public on(name: string, callback: Function, _this?: this, isOnce: boolean = false): void {
        if (this._eventMap[name] === undefined) {
            this._eventMap[name] = [];
        }
        this._eventMap[name].push({
            _this: this,
            isOnce: isOnce,
            callback: callback,
        });
    }
    public once(name: string, callback: Function, _this?: this): void {
        this.on(name, callback, _this, true);
    }

    //解监事件
    public off(name: string, callback?: Function, _this?: this): void {
        
        if (this._eventMap[name] === undefined) {
            return undefined;
        } 
        
        if (!(callback || _this)) {
            this._eventMap[name] = [];
        }
        
        let indexs = [];
        let events = this._eventMap[name];
        const length = events.length - 1;
        if (_this) {
            for (let i = length; i >= 0; --i) {
                if (events[i]._this == _this) {
                    indexs.push(i);
                }
            }
        } else {
            for (let i = length; i >= 0; --i) {
                if (events[i].callback == callback) {
                    indexs.push(i);
                }
            }
        }
        for (let i of indexs) {
            events.splice(i, 1);
        }
    }
}