/*
    用户数据
*/

import EventManager from './EventManager';

export default class User extends EventManager {

    //数据映射
    private _dataMap: any = {};

    //存储键值
    private readonly _key: string;
    
    constructor(key: string) {
        super();
        this._key = key;
        this._load();
    }

    //临时存储
    public static temporary(key: string): User {
        return new User(key);
    }

    //加载数据
    private _load(): void {
        try {
            this._dataMap = JSON.parse(localStorage.getItem(this._key) || '{}');
        } catch (e) {
            this._dataMap = {};
        }
    }

    //保存数据
    private _save(): boolean {
        try {
            localStorage.setItem(this._key, JSON.stringify(this._dataMap));
            return true;
        } catch (e) {
            return false;
        }
    }

    //设置数据
    public set(name: string, value: any): boolean {
        this._dataMap[name] = value;
        return this._save();
    }

    //批量设置
    public setBetch(data: any, list?: string[]): boolean {
        if (list !== undefined) {
            for (let k of list) {
                this._dataMap[k] = data[k];
            }
        } else {
            for (let k in data) {
                this._dataMap[k] = data[k];
            }
        }
        return this._save();
    }

    //获取数据
    public get(name: string): any {
        return this._dataMap[name];
    }
    //批量获取
    public getBetch(list: string[], data?: any): any {
        let _data: any = data || {};
        for (let k of list) {
            _data[k] = this._dataMap[k];
        }
        return _data;
    }

    //删除数据
    public del(name: string): boolean {
        if (this._dataMap[name] !== undefined) {
            delete this._dataMap[name];
            return this._save();
        }
        return false;
    }
    //清空数据
    public empty(): boolean {
        this._dataMap = {};
        return this._save();
    }
}