

















































import { Vue, Component } from 'vue-property-decorator';
import { List, Cell } from 'vant';
import Back from "@/components/Back.vue";

@Component({
    components: {
        'van-list': List,
        'van-cell': Cell,
      back:Back

    }
})
export default class Message extends Vue {
    //列表数据
    list: any = [];
    //当前页码
    page: number = 1;
    //加载状态
    loading: boolean = false;
    //完成状态
    finished: boolean = false;
    
    //获取列表
    getList(case_id: number, page: number) {
        this.loading = true;
        this.axios.post(this.$URL.MESSAGE_LIST, { type: 2, page }).then((res: any) => {
            if (res.code == 1) {
                const list = res.data.list;
                if (list.length > 0) {
                    this.page = page;
                    for (let item of list) {
                        this.list.push(item);
                    }
                } else {
                    this.finished = true;
                }
            }
        }).finally(() => {
            this.loading = false;
        });
    }

    //列表加载
    onLoad() {
        const id = Number(this.$route.query.id);
        this.getList(id, this.page + 1);
    }

    created() {
        const id = Number(this.$route.query.id);
        this.getList(id, this.page);
    }
}
