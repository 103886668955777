var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page register"},[_c('back',{on:{"routerBack":function($event){return _vm.$router.back()}}}),_c('van-form',{staticClass:"register_form",on:{"submit":_vm.onRegister}},[_c('figure',{staticClass:"account_form-logo"},[_c('img',{attrs:{"src":_vm.system_info.logo}}),_c('figcaption',[_vm._v(_vm._s(_vm.system_info.name))])]),_c('van-field',{attrs:{"type":"text","name":"case_id","placeholder":"请输入案件ID","border":false,"label":"案件ID","maxlength":"11","rules":[
            { required: true, message: ' 请输入案件ID ' } ]},model:{value:(_vm.fields.case_id),callback:function ($$v) {_vm.$set(_vm.fields, "case_id", $$v)},expression:"fields.case_id"}}),_c('van-field',{staticClass:"name_label",attrs:{"type":"text","name":"name","placeholder":"Please enter name of the creditor","border":false,"label":"债权人姓名","rules":[
            { required: true, message: ' Please enter name of the creditor ' } ]},model:{value:(_vm.fields.name),callback:function ($$v) {_vm.$set(_vm.fields, "name", $$v)},expression:"fields.name"}}),_c('van-field',{attrs:{"type":"text","name":"mobile","placeholder":"Please enter your phone number","label":"手机号","maxlength":"11","border":false,"rules":[
                    { required: true, message: 'Please enter your mobile number' },
                    { pattern: /^\d{11}$/, message: '请输入正确的手机号码' }
                ]},model:{value:(_vm.fields.mobile),callback:function ($$v) {_vm.$set(_vm.fields, "mobile", $$v)},expression:"fields.mobile"}}),_c('van-field',{attrs:{"type":"text","name":"sms_code","placeholder":"请输入验证码","label":"Verification Code","maxlength":"8","format-trigger":"onBlur","border":false,"rules":[{ required: true, message: '请输入验证码' }]},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('send-verify-code',{staticClass:"btn-sms-code",attrs:{"mobile":_vm.fields.mobile,"plat_id":_vm.system_info.id,"event":"user_register"},on:{"fail":function($event){return _vm.$toast.fail($event)},"success":function($event){return _vm.$toast.success('Sent successfully')}}},[_vm._v(" 获取验证码 ")])]},proxy:true}]),model:{value:(_vm.fields.sms_code),callback:function ($$v) {_vm.$set(_vm.fields, "sms_code", $$v)},expression:"fields.sms_code"}}),_c('van-field',{staticClass:"psw_field",attrs:{"type":"password","name":"password","placeholder":"设置密码","label":"设置密码","border":false,"format-trigger":"onBlur","autocomplete":"off","rules":[
                    { required: true, message: 'Please enter password' },
                    { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d$@$!%*?&]{6,15}$/, message: 'Passwords are 6-15 characters long, with at least one number and one letter' }
                ]},model:{value:(_vm.fields.password),callback:function ($$v) {_vm.$set(_vm.fields, "password", $$v)},expression:"fields.password"}}),_c('van-field',{staticClass:"psw_p_field",attrs:{"type":"password","name":"new_pwd","placeholder":"再次输入密码","label":"再次输入密码","format-trigger":"onBlur","autocomplete":"off","border":false,"rules":[
                    { required: true, message: '请再次输入密码' },
                    {
                        validator: function (value, rule) { return _vm.fields.password == _vm.fields.new_pwd },
                        message: '两次输入密码不一致，请重新输入！' 
                    }
                ]},model:{value:(_vm.fields.new_pwd),callback:function ($$v) {_vm.$set(_vm.fields, "new_pwd", $$v)},expression:"fields.new_pwd"}}),_c('van-checkbox',{staticClass:"account_form-checkbox",attrs:{"shape":"square"},model:{value:(_vm.fields.is_pass),callback:function ($$v) {_vm.$set(_vm.fields, "is_pass", $$v)},expression:"fields.is_pass"}},[_vm._v(" 我已阅读并同意 "),_c('router-link',{staticClass:"account_form-button",attrs:{"to":"/privacy_policy","tag":"span"}},[_vm._v("《Privacy Policy》")]),_vm._v(" 和 "),_c('router-link',{staticClass:"account_form-button",attrs:{"to":"/user_agreement","tag":"span"}},[_vm._v("《User Agreement》")])],1),_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit"}},[_vm._v("注册")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }