




























































































































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import Conference from '@/components/Conference.vue';
import Aliplayer from '@/components/Aliplayer.vue';
import { Tab, Tabs, RadioGroup, Radio , Checkbox, CheckboxGroup , Popover } from 'vant';
import Chat from '@/components/Chat.vue';
import Message from '@/components/Message.vue';
import { Dialog } from 'vant';
import Clipboard from 'clipboard';
import wx from 'weixin-js-sdk';
import Back from "@/components/Back.vue";

@Component({
    components: {
        conference: Conference,
        'ali-player': Aliplayer,
        'van-tab': Tab,
        'van-tabs': Tabs,
        'chat': Chat,
        'message': Message,
        'van-radio-group': RadioGroup,
        'van-radio': Radio,
        'van-checkbox':Checkbox,
        'van-checkbox-group':CheckboxGroup,
        'van-popover':Popover,
        back:Back
    }
})
export default class MeetingRoom extends Vue {

	public test:any=[
		{test:'1'}
	]
	
    //标签索引
    tabActive: number = 0;
    //聊天框高度
    chatHeight: string = '';
    //标签页高度
    tabHeight: string = '';

    //详情信息
    detail: any = {};
    //文件列表
    fileList: any = [];
    //投票事项
    matters: any = [];
	
	private matter_committee:any=null;
	private members:any=[];
	// 是否同意设立债委会
	private consent_set_value:any=null;
	//债委会成员选项
	private members_ids:any=[];
	// 是否有表决权
	private is_vote_power:boolean=false;
	// 是否所有事项都没有表决权
	private is_vote_power_all:any=[];
	// 表决事项是否禁用
	private matters_disabled:any={
		matters:[],
		matter_committee:{
			is_consent_set:false,
			members:false,
			vote_btn:false,  //是否有债委会表决权
		},
		agent:false
	};
	
	// 其他人员
	private other_member:string='';
	private show_other_input:boolean=false;
	private getMemberIdsChange(e:any){
		let other:any ;
		this.matter_committee.members.forEach((item:any)=>{
			if(item.name == '其他人员'){
				other = item.id
			}
		})
		this.show_other_input = e.some((item:any)=>{
			return item == other
		})
		if(!this.show_other_input){
			this.other_member = ''
		}
	}
	
	// 代理债权人列表
	private creditor_list:any=null;
	// 已选中债权人列表
	private creditor_list_select:any=[];
	// 选择代理人
	private selectCreditor( id:number , is_late_voting:any){
		this.axios.post(this.$URL.GET_MATTERS_INFO , { meeting_id:this.meetingId , agent_id:id}).then((res:any)=>{
			// console.log(res , is_late_voting)
			if(res.code == 1){
				if(is_late_voting === '1'){
					if(res.data.matter_committee){
						this.updateVote(res.data.matters , res.data.matter_committee  );
					} else {
						this.updateVote(res.data.matters , res.data.matters_committee_info  );
						this.matters_disabled.matter_committee.is_consent_set = true ;
						this.matters_disabled.matter_committee.members = true ;
					}
					// this.updateVote( 1 , res.data.matters , res.data.matter_committee );
					this.matters_disabled.agent = true ;			//选择已投票的债权人后，表决事项要禁用
					this.show_other_input = false ;
				}else{
					for(let k in this.creditor_list ){
						if( id == this.creditor_list[k].id){
							this.creditor_list[k].is_select = !this.creditor_list[k].is_select
						}
					}
					this.updateVote( res.data.matters , res.data.matter_committee );
					this.matters_disabled.agent = false ;			//选择已投票的债权人后，表决事项要禁用
				}
				// console.log( this.matters , '表决事项' , is_late_voting )
			}
		})
	}
	
	private selectAll:boolean=true;
	// 全选/取消全选
	private creditorSelectAll(){
		if(this.creditor_list.some((item:any)=>{ return item.is_late_voting === '0' })){
			this.selectAll = !this.selectAll ;
			for(let k in this.creditor_list){
        if(this.creditor_list[k].is_late_voting == '0'){
          this.creditor_list[k].is_select = this.selectAll
        }
			}
			for(let i in this.matters){
				this.matters_disabled.matters[i] = !this.matters[i].vote_btn;
			}
			if(this.matters_disabled.matter_committee.vote_btn){
				this.matters_disabled.matter_committee.is_consent_set = false ;
				this.matters_disabled.matter_committee.members = false ;
			}else{
				this.matters_disabled.matter_committee.is_consent_set = true ;
				this.matters_disabled.matter_committee.members = true ;
			}
			this.matters_disabled.agent = false ;
		}

	}

	
	private showVoteItem:boolean=false;
	// 展示所有债权人
	private ShowVoteItem(){
		this.showVoteItem = !this.showVoteItem;
		let show:any = document.getElementsByClassName('creditor_vote_list')[0];
		if(this.showVoteItem){
			let h = (((this.creditor_list.length + 1)/4)*50) + 'px';
			show.style.height = h ;
			show.style.overflowY = 'hidden' ;

		}else{
			show.style.height = '50px' ;
			show.style.overflowY = 'hidden' ;
		}
	}

    //会议Id
    meetingId!: number;


	// 获取表决数据
	private updateVote( matters:any , matter_committee?:any , agent?:any){/* type：0首次进入页面 ，1:选择已表决的代理人 */
		if(matters){
			// console.log(matters)
			let vote_list = matters.map((item:any,index:any)=>{
				if(matter_committee!=null){
					if((index+1) < matter_committee.sort){
						item['index']=index+1
					}else{
						item['index']=index+2
					}
				}else{
					item['index']=index+1
				}
				if(item.result){
					this.$set(this.matters_disabled.matters , index , true);
				}else{
					this.$set(this.matters_disabled.matters , index , !item.vote_btn );
				}
				// this.matters_disabled.matters.push( !item.vote_btn ) //判断当前事项  (vote_btn代表当前表决是否有表决权 ，true有表决权 ， false无表决权)
				return item
			})
			this.matters = vote_list;
			// console.log(this.matters_disabled.matters , '表决权')
		}
		if( matter_committee){
			this.matters_disabled.matter_committee.members = matter_committee.is_late_voting ==='0'?false:true ; //判断当前债委会是否表决过
			this.matter_committee={ ...matter_committee }
			if(matter_committee.is_late_voting == 1){ //如果已表决过
				this.consent_set_value = Number(matter_committee.late_voting.result);
				this.members_ids = matter_committee.late_voting.members_ids.split(',').map((item:any)=>{return Number(item)});
			}else{//没表决过的
				// if(detail.is_late_voting == '0'){
					for(let i=0; i<this.matter_committee.length; i++){
						if(i<=this.matter_committee.members.length-1){
							this.$set(this.members_ids,i,this.matter_committee.members[i].id)
						}
					}
				// }
			}
		}
		if(agent){
			this.creditor_list = agent.members.map((item:any)=>{
				if(item.is_late_voting === '1'){
					return { ... item , is_select :false}
				}else{
					return { ...item , is_select:true }
				}
			})
		}
	}
	
	// // 更新会议数据
	private updateMeetingInfo(){
		this.axios.post(this.$URL.MEETING_JOIN, { id:this.meetingId }).then((res: any) => {
			if (res.code == 1) {
				console.log(res)
				const data = res.data;
				this.detail = data.detail;
				this.fileList = data.files;
				
				if(data.matter_committee){
					this.updateVote(  data.matters , data.matter_committee , data.agent );
					this.matters_disabled.matter_committee.vote_btn = true ;
				} else {
					this.updateVote(  data.matters , data.matters_committee_info , data.agent );
					this.matters_disabled.matter_committee.is_consent_set = true ;
					this.matters_disabled.matter_committee.members = true ;
					this.matters_disabled.matter_committee.vote_btn = false ;
				}
				
				if( data.detail.is_late_voting === '1'){ //判断投票是否截止
					for( let k in this.matters_disabled.matters){
						this.matters_disabled.matters[k] = true
					}
					this.matters_disabled.matter_committee.is_consent_set = true ;
					this.matters_disabled.matter_committee.members = true ;
					this.matters_disabled.agent = true ;
				}
				
				// 如果有代理人投票 --所有表决项都开放
				if( data.agent ){
					this.matters_disabled.agent = false ;
				}
				
			} else {
				this.$notify(res.message);
				this.$router.back();
			}
		});
	}

    //加入会议
    joinMeeting() {
        const id = Number(this.$route.query.id);
        this.$toast.loading({
            message: "正在加入会议中...",
            forbidClick: true,
            duration: 0
        });
        this.axios.post(this.$URL.MEETING_JOIN, { id }).then((res: any) => {
          if (res.code == 1) {
            console.log(res)
            const data = res.data;
            this.detail = data.detail;
            this.fileList = data.files;

				if(data.matter_committee){
					this.updateVote(  data.matters , data.matter_committee , data.agent );
					this.matters_disabled.matter_committee.vote_btn = true ;
				} else {
					this.updateVote(  data.matters , data.matters_committee_info , data.agent );
					this.matters_disabled.matter_committee.is_consent_set = true ;
					this.matters_disabled.matter_committee.members = true ;
					this.matters_disabled.matter_committee.vote_btn = false ;
				}
				if( data.detail.is_late_voting === '1'){ //判断投票是否截止
					for( let k in this.matters_disabled.matters){
						this.matters_disabled.matters[k] = true
					}
					this.matters_disabled.matter_committee.is_consent_set = true ;
					this.matters_disabled.matter_committee.members = true ;
					this.matters_disabled.agent = true ;
				}
				// 如果有代理人投票 --所有表决项都开放
				if( data.agent ){
					if(this.creditor_list.every((item:any)=>{return item.is_late_voting === '1'})){
						this.matters_disabled.agent = true ;
					}else{
						this.matters_disabled.agent = false ;
					}
				}
        this.meetingId = id ;

        //会议开始时间
        const start_time = new Date(data.detail.start_time).getTime()
        this.onTimer(start_time)

        if (this.detail.meeting_type_id == 0) {
          //会议模式
          //(this.$refs.conferenceVideo as any).join(data.res);
          this.$toast.fail("暂不支持，请前往电脑端参会");
          this.$router.back();
        } else if (this.detail.status != 0) {
          //直播模式
          (this.$refs.aliPlayer as any).join(data.res.config);
          //加入聊天群组
          // (this.$refs.chat as any).joinGroup(id, this.$store.state.account.name);
          //加入留言问题
          // (this.$refs.message as any).joinGroup(id, this.$store.state.account.name);
        }} else {
              this.$notify(res.message);
              this.$router.back();
            }
          this.$toast.clear();
        });
    }

    //签到
    signIn() {
        const meeting_id = Number(this.$route.query.id);
        this.$toast.loading({
            message: "正在签到",
            forbidClick: true,
            duration: 0,
            overlay: true
        });
        
        this.axios.post(this.$URL.MEETING_SIGN_IN, { meeting_id }).then((res: any) => {
            if (res.code == 1) {
                this.$toast.success("Sign in successfully");
                this.detail.is_sign_in = 1;
            } else {
                this.$toast.fail(res.message);
            }
        });
    }
	
	private open_file(url:string){
		wx.miniProgram.navigateTo({url: '/pages/open_file/open_file?path=' + url})
	}

	// 复制文件链接
	private onCopy(){
		var clipboard = new Clipboard('.copy_link')  
		clipboard.on('success', (e:any) => {
			console.log(e)
			this.$toast.success("复制成功,请打开浏览器下载文件！");
				// 释放内存  
				clipboard.destroy()
			})  
		clipboard.on('error', (e:any) => {
			console.log(e)
				// 不支持复制  
			this.$toast.fail('该浏览器不支持自动复制！')  
				// 释放内存  
			clipboard.destroy()  
		}) 
	}

    //投票
    private vote(matters_array: any) {
        Dialog.confirm({
            title: '提示',
            message: '提交后不可更改，确认是否提交',
        })
        .then(() => {
            const meeting_id = Number(this.$route.query.id);
            // console.log(matters_array,meeting_id)
            this.$toast.loading({
                message: "正在投票",
                forbidClick: true,
                duration: 0
            });
			let agent:any=[];
			if(this.creditor_list){
				for(let k in this.creditor_list){
					if(this.creditor_list[k].is_select){
						agent.push(this.creditor_list[k].id)
					}
				}
				agent = agent.join(',')
			}else{
				agent=''
			}
			// console.log(agent)
			if(this.matter_committee == null){
				this.axios.post(this.$URL.MEETING_VOTE, {
					meeting_id,
					matters_array: JSON.stringify(matters_array),
					agent_ids: agent
				}).then((res: any) => {
					if (res.code == 1) {
						this.$toast.success("success");
						this.detail.is_late_voting = '1';
						this.updateMeetingInfo()
					} else {
						this.$toast.fail(res.message);
						setTimeout(()=>{
							this.joinMeeting();
						} , 1500)
					}
				});
			}else{
				if(this.consent_set_value == 1){// 判断是否同意设立债委会
					this.members_ids = [];
				}
				if(!this.show_other_input){//判断是否选择--其他人员
					this.other_member = ''
				}
				// console.log(meeting_id , matters_array , this.consent_set_value , this.members_ids.join(',') , this.other_member)
				this.axios.post(this.$URL.MEETING_VOTE, {
					meeting_id,
					matters_array: JSON.stringify(matters_array),
					consent_set_value:this.consent_set_value,
					members_ids:this.members_ids.join(','),
					other_member:this.other_member,
					agent_ids: agent
				}).then((res: any) => {
					if (res.code == 1) {
						this.$toast.success("success");
						this.detail.is_late_voting = '1';
						this.updateMeetingInfo();
					} else {
						this.$toast.fail(res.message);
						setTimeout(()=>{
							this.joinMeeting();
						} , 1500)
					}
				});
			}
        })
        .catch(() => {
            Dialog.close();
        });
    }

    //监听提交投票
    onSubmitVote() {
		let checkedNum=0
		// console.log(this.members_ids)
		
		if(this.creditor_list){
			if( this.creditor_list.every((item:any)=>{ return item.is_select == false })){
				Dialog.confirm({
				title: '提示',
				message: '请至少选择一个债权人',
				}).then(()=>{Dialog.close()}).catch(()=>{Dialog.close()})
				return undefined
			}
		}
		
		if(this.matter_committee!==null && !this.matters_disabled.matter_committee.members){
			if(this.matter_committee.is_consent_set==1&&this.consent_set_value==null){
				checkedNum=checkedNum+1
			}
			if((this.matter_committee.is_consent_set == 0 || this.consent_set_value == 0)){
				if(this.members_ids.length < this.matter_committee.min_num||this.members_ids.length>this.matter_committee.max_num){
					// this.$message.error(`请选择${this.matter_committee.min_num}-${this.matter_committee.max_num}个债委会成员`)
					Dialog.confirm({
					title: '提示',
					message: `请选择${this.matter_committee.min_num}-${this.matter_committee.max_num}个选项`,
					}).then(()=>{Dialog.close()}).catch(()=>{Dialog.close()})
					return undefined
				}
			}
			if(this.show_other_input&&this.other_member==''){
					Dialog.confirm({
					title: '提示',
					message: '请输入自荐或推荐人员名单',
					}).then(()=>{Dialog.close()}).catch(()=>{Dialog.close()})
				return undefined
			}
		}


      const mattersArray =  this.matters.map((val: any) => {
			if(val.vote_btn||typeof (val.vote_btn)=='undefined'){
				checkedNum=checkedNum+1
				if(val.result=="0"||val.result=="1"||val.result=="2"){
					checkedNum = checkedNum - 1
				}
			}
			return {
				matter_id: val.id,
				result: val.result,
			};
      });
		if(checkedNum > 0){
			Dialog.confirm({
			title: '提示',
			message: '你有表决项未选择',
			}).then(()=>{Dialog.close()}).catch(()=>{Dialog.close()})
		}else{
			this.vote(mattersArray);
		}
    }

  //会议开始倒计时
  private timer:any=null;
  private onTimer(start_time:any){
    let now_time = new Date().getTime();
    // console.log( !this.timer ,  start_time-now_time )
    if(!this.timer && now_time < start_time ){
      const delay = ((start_time - now_time) + 5000);
      // 定时器最大执行时间
      if(delay < 2147483647 ){
        this.timer = setTimeout(()=>{
          // console.log( 111 , delay >2147483647)
          this.$router.go(0)
        } , delay)
      }
    }
  }



    //监听选项卡
    // onTabChange(name: number) {
    //     // if (name == 1) {
    //     //     (this.$refs.message as any).loadIssuesList();
    //     // }
    // }

    created(){
      this.joinMeeting();
    }

    mounted(){
        this.$nextTick(() => {
            const h = (this.$refs.tabs as any).$el.clientHeight;
            this.tabHeight = 'height: calc(' + h + 'px - 1.17333rem)';
            this.chatHeight = 'height: calc(' + h + 'px - 1.41333rem)';
        });
    }

  beforeDestroy() {
    clearTimeout(this.timer);
    // console.log(this.timer)
  }
}
